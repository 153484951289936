import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';
import { useSelector } from 'react-redux';
import { IMusselFarm, IOysterFarm } from '../../entities/farms.entities';
import { ISizedOyster } from '../../entities/utils.entities';

const getAccountByFarmID = createSelector(
  (state: RootState, farmID: number | undefined) => {
    const myFarms = state.farms.farmsData;
    const extraFarms = state.extra.farms;
    if (!farmID || myFarms.some(x => x.id === farmID)) {
      return undefined;
    }
    if (!extraFarms) {
      return undefined;
    }
    const farm = extraFarms.find(x => x.id === farmID);
    return farm?.account_id;
  },
  id => id,
);

export const SelectIsMyFarm = (farmID: number | undefined) =>
  useSelector((state: RootState) => !getAccountByFarmID(state, farmID));

const getInventoriesByFarmID = createSelector(
  (state: RootState, farmID: number | undefined) => {
    const accountID = getAccountByFarmID(state, farmID);
    if (accountID) {
      return (
        state.extra.inventories?.filter(x => x.account_id === accountID) ?? []
      );
    } else {
      return state.inventories.inventories;
    }
  },
  inventories => inventories,
);

export const SelectInventoriesByFarmID = (farmID: number | undefined) =>
  useSelector((state: RootState) => getInventoriesByFarmID(state, farmID));

export const selectAllFarms = createSelector(
  (state: RootState) => state.farms.farmsData,
  (state: RootState) => state.extra.farms,
  (myFarms, extraFarms) => (extraFarms ? [...myFarms, ...extraFarms] : myFarms),
);

const getFarmsByFarmID = createSelector(
  (state: RootState, farmID: number | undefined) => {
    const myFarms = state.farms.farmsData;
    const extraFarms = state.extra.farms;
    if (!farmID || myFarms.some(x => x.id === farmID)) {
      return myFarms;
    }
    if (!extraFarms) {
      return [];
    }
    const farm = extraFarms.find(x => x.id === farmID);
    return extraFarms.filter(x => x.account_id === farm?.account_id);
  },
  farms => farms,
);

export const SelectFarmsByFarmID = (farmID: number | undefined) =>
  useSelector((state: RootState) => getFarmsByFarmID(state, farmID));

export const SelectMusselFarmsByFarmID = (farmID: number | undefined) =>
  useSelector((state: RootState) => getFarmsByFarmID(state, farmID)).filter(
    x => x.type === 'MUSSEL',
  ) as IMusselFarm[];

export const SelectOysterFarmsByFarmID = (farmID: number | undefined) =>
  useSelector((state: RootState) => getFarmsByFarmID(state, farmID)).filter(
    x => x.type === 'OYSTER',
  ) as IOysterFarm[];

export const SelectSeaweedFarmsByFarmID = (farmID: number | undefined) =>
  useSelector((state: RootState) => getFarmsByFarmID(state, farmID)).filter(
    x => x.type === 'SEAWEED',
  );

const getSpatsByFarmID = createSelector(
  (state: RootState, farmID: number | undefined) => {
    const accountID = getAccountByFarmID(state, farmID);
    if (accountID) {
      return (
        state.extra.spatStorages?.filter(x => x.account_id === accountID) ?? []
      );
    } else {
      return state.farms.spatStorages;
    }
  },
  spats => spats,
);

export const SelectSpatStoragesByFarmID = (farmID: number | undefined) =>
  useSelector((state: RootState) => getSpatsByFarmID(state, farmID));

export const SelectMusselSpatsByFarmID = (farmID: number | undefined) =>
  useSelector((state: RootState) => getSpatsByFarmID(state, farmID)).filter(
    x => x.type === 'MUSSEL',
  );

export const SelectOysterSpatsByFarmID = (farmID: number | undefined) =>
  useSelector((state: RootState) => getSpatsByFarmID(state, farmID)).filter(
    x => x.type === 'OYSTER',
  );

export const SelectSeaweedSpatsByFarmID = (farmID: number | undefined) =>
  useSelector((state: RootState) => getSpatsByFarmID(state, farmID)).filter(
    x => x.type === 'SEAWEED',
  );

const getIsBudgetVisible = createSelector(
  (state: RootState, farmID: number | undefined) => {
    if (!farmID || state.farms.farmsData.some(x => x.id === farmID)) {
      return !!state.auth.profile?.account?.show_budget_menu;
    }
    const farm = state.extra.farms?.find(x => x.id === farmID);

    return farm && !!farm.is_financed;
  },
  visible => visible,
);

export const SelectIsBudgetVisible = (farmID: number | undefined) =>
  useSelector((state: RootState) => getIsBudgetVisible(state, farmID));

const getIsEditable = createSelector(
  (state: RootState, farmID: number | undefined) => {
    const profile = state.auth.profile;
    if (!profile) return false;
    const res =
      ['owner', 'admin'].includes(profile.role) || !!profile.edit_permission;
    if (!farmID) return res;
    const accountID = getAccountByFarmID(state, farmID);
    return !accountID ? res : false;
  },
  editable => editable,
);

const getIsFarmable = createSelector(
  (state: RootState, farmID: number | undefined) => {
    const profile = state.auth.profile;
    if (!profile) return false;
    const res =
      ['owner', 'admin'].includes(profile.role) || !!profile.edit_permission;

    if (!farmID) return res;
    const accountID = getAccountByFarmID(state, farmID);
    return !accountID ? res : true;
  },
  farmable => farmable,
);

export const SelectIsEditable = (farmID: number | undefined) =>
  useSelector((state: RootState) => getIsEditable(state, farmID));

export const SelectIsFarmable = (farmID: number | undefined) =>
  useSelector((state: RootState) => getIsFarmable(state, farmID));

const getUtilsByFarmID = createSelector(
  (state: RootState, farmID: number | undefined) => {
    if (!farmID) {
      return state.utils.farmUtils;
    }
    const accountID = getAccountByFarmID(state, farmID);
    if (accountID) {
      return state.extra.utils?.filter(x => x.account_id === accountID) ?? [];
    } else {
      return state.utils.farmUtils;
    }
  },
  utils => utils,
);

export const SelectUtilsByFarmID = (farmID: number | undefined) =>
  useSelector((state: RootState) => getUtilsByFarmID(state, farmID));

export const SelectSizedOystersByFarmID = (farmID: number | undefined) =>
  useSelector((state: RootState) => getUtilsByFarmID(state, farmID)).filter(
    x => x.type === 'oyster_harvest',
  ) as ISizedOyster[];

export const SelectSeasonsByFarmID = (farmID: number | undefined) =>
  useSelector((state: RootState) => getUtilsByFarmID(state, farmID)).filter(
    x => x.type === 'season',
  );

const getCustomFieldsByFarmID = createSelector(
  (state: RootState, farmID: number | undefined) => {
    const accountID = getAccountByFarmID(state, farmID);
    if (!accountID) {
      return state.automation.customFields;
    } else {
      return (
        state.extra.customFields?.filter(x => x.account_id === accountID) ?? []
      );
    }
  },
  customFields => customFields,
);

export const SelectAssessFields = (farmID: number | undefined) =>
  useSelector((state: RootState) =>
    getCustomFieldsByFarmID(state, farmID),
  ).filter(x => x.type === 'ASSESSMENT');

export const SelectSeedingFields = (farmID: number | undefined) =>
  useSelector((state: RootState) =>
    getCustomFieldsByFarmID(state, farmID),
  ).filter(x => x.type === 'SEEDING');

export const SelectHarvestFields = (farmID: number | undefined) =>
  useSelector((state: RootState) =>
    getCustomFieldsByFarmID(state, farmID),
  ).filter(x => x.type === 'HARVEST');

const getSettingsByFarmID = createSelector(
  (state: RootState, farmID: number | undefined) => {
    if (!farmID) {
      return state.auth.settings;
    }
    const accountID = getAccountByFarmID(state, farmID);
    if (!accountID) {
      return state.auth.settings;
    } else {
      return undefined;
    }
  },
  settings => settings,
);

export const SelectSettingsByFarmID = (farmID: number | undefined) =>
  useSelector((state: RootState) => getSettingsByFarmID(state, farmID));

const getTagsByFarmID = createSelector(
  (state: RootState, farmID: number | undefined) => {
    const accountID = getAccountByFarmID(state, farmID);
    if (!accountID) {
      return state.automation.tags;
    } else {
      return state.extra.tags?.filter(x => x.account_id === accountID) ?? [];
    }
  },
  tags => tags,
);

export const SelectTagsByFarmID = (farmID: number | undefined) =>
  useSelector((state: RootState) => getTagsByFarmID(state, farmID));

export const selectAllTags = createSelector(
  (state: RootState) => state.automation.tags,
  (state: RootState) => state.extra.tags,
  (tags, extraTags) => [...(tags ?? []), ...(extraTags ?? [])],
);

const getAccountTypeByFarmID = createSelector(
  (state: RootState, farmID: number | undefined) => {
    const accountID = getAccountByFarmID(state, farmID);
    if (!accountID) {
      return state.auth.profile?.account_type;
    }
    const account = state.extra.accounts?.find(x => x.id === accountID);
    return account?.type;
  },
  type => type,
);

export const SelectIsGrower = (farmID: number | undefined) =>
  useSelector(
    (state: RootState) => getAccountTypeByFarmID(state, farmID) === 'grower',
  );

const getAccountSettingByFarmID = createSelector(
  (state: RootState, farmID: number | undefined) => {
    const accountID = getAccountByFarmID(state, farmID);
    if (!accountID) {
      return state.auth.profile?.account;
    }
    const account = state.extra.accounts?.find(x => x.id === accountID);
    return account?.setting;
  },
  account => account,
);

export const SelectAccountSetting = (farmID: number | undefined) =>
  useSelector((state: RootState) => getAccountSettingByFarmID(state, farmID));

const getProfileByFarmID = createSelector(
  (state: RootState, farmID: number | undefined) => {
    const accountID = getAccountByFarmID(state, farmID);
    if (!accountID) {
      return state.auth.profile;
    }
    return undefined;
  },
  role => role,
);

export const SelectProfileRole = (farmID: number | undefined) =>
  useSelector((state: RootState) => getProfileByFarmID(state, farmID)?.role);

export const SelectProfileByFarmID = (farmID: number | undefined) =>
  useSelector((state: RootState) => getProfileByFarmID(state, farmID));

const getUserMetaByFarmID = createSelector(
  (state: RootState, farmID: number | undefined) => {
    const accountID = getAccountByFarmID(state, farmID);
    if (!accountID) {
      return state.auth.userMeta;
    }
    return undefined;
  },
  meta => meta,
);

export const SelectUserMeta = (farmID: number | undefined) =>
  useSelector((state: RootState) => getUserMetaByFarmID(state, farmID));

const getXeroContactsByFarmID = createSelector(
  (state: RootState, farmID: number | undefined) => {
    const accountID = getAccountByFarmID(state, farmID);
    if (!accountID) {
      return state.subscription.xero_contacts;
    }
    return undefined;
  },
  contacts => contacts,
);

export const SelectXeroContactsByFarmID = (farmID: number | undefined) =>
  useSelector((state: RootState) => getXeroContactsByFarmID(state, farmID));
