import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IAssessmentResource,
  ILineResource,
  IMaintenanceResource,
  ISeedInventoryResource,
  ISeedManage,
} from '../../entities/farms.entities';
import {
  IWeatherConditionResource,
  TBusinessType,
} from '../../entities/general.entities';
import { DropdownMenu, ModalComponent, Title } from '../shared';
import { Modal } from 'antd';
import ImageGallery from 'react-image-gallery';
import MaintenanceModal from '../farm-modals/MaintenanceModal';
import AssessmentModal from '../farm-modals/AssessmentModal';
import WeatherInfoModal from '../farm-modals/WeatherInfoModal';
import FloatManageModal from '../farm-modals/FloatManageModal';
import { ICustomField } from '../../entities/automation.entities';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import weather_icon from '../../images/weather-icon.png';
import { sendSingleRequest } from '../../apis';
import { showFeedback } from '../../store/ui/ui.actions';
import 'react-image-gallery/styles/css/image-gallery.css';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { TLang } from '../../entities/ui.entities';
import {
  SelectAssessFields,
  SelectIsGrower,
} from '../../store/extra/extra.selector';
import GrowerMaintenanceModal from '../farm-modals/GrowerMaintenanceModal';
import { directionChar } from '../../lib/form.helpers';

interface IImage {
  original: string;
  thumbnail: string;
}

const SeedManageRow: React.FC<{ item: ISeedManage; lang?: TLang }> = ({
  item,
  lang,
}) => (
  <>
    <td className='ant-table-cell'>
      <span>{defaultDateFormat(item.manage_date)}</span>
    </td>
    <td className='ant-table-cell' colSpan={2}>
      {translate(lang, 'Line depth history')}
    </td>
    <td className='ant-table-cell'>{`${item.submersion} m`}</td>
    <td className='ant-table-cell' colSpan={11}></td>
  </>
);

const FloatsRow: React.FC<{
  item: ISeedInventoryResource;
  colSpan: number;
  onEdit?: (d: ISeedInventoryResource) => void;
  onDelete?: (d: ISeedInventoryResource) => void;
  onWeatherOpen: (w: IWeatherConditionResource) => void;
  lang?: TLang;
}> = ({ item, colSpan, onEdit, onDelete, onWeatherOpen, lang }) => (
  <>
    <td className='ant-table-cell'>
      <span>{defaultDateFormat(item.manage_date)}</span>
      <br /> <span>{item.user?.name ?? translate(lang, 'Unknown')}</span>
      {item.verifier && (
        <>
          <br />
          <span className='verifier-name'>
            {translate(lang, 'Verified by %s', item.verifier.name)}
          </span>
        </>
      )}
    </td>
    <td className='ant-table-cell' colSpan={2}>
      {translate(lang, 'Inventory management')}
    </td>
    <td className='ant-table-cell' colSpan={colSpan}>
      {}
      {item.status === 'IN'
        ? translate(
            lang,
            '%s deducted and returned to storage',
            `${item.quantity} ${item.inventory.type} ${item.inventory.subtype}`,
          )
        : translate(
            lang,
            '%s added',
            `${item.quantity} ${item.inventory.type} ${item.inventory.subtype}`,
          )}
    </td>
    <td className='ant-table-cell'>
      <span></span>
    </td>
    <td className='ant-table-cell'>
      {!item.weather_condition ? (
        <span></span>
      ) : (
        <div
          className='btn__modal'
          style={{ display: 'flex' }}
          onClick={onWeatherOpen.bind(this, item.weather_condition)}
        >
          <img
            className='ant-image-img'
            src={weather_icon}
            alt='weather'
            style={{ maxWidth: '20px' }}
          />
          <span style={{ marginLeft: '5px' }}>{translate(lang, 'View')}</span>
        </div>
      )}
    </td>
    {onEdit && onDelete && (
      <td className='ant-table-cell'>
        <DropdownMenu
          data={item}
          onEdit={onEdit}
          onDeleteRow={onDelete}
          type='isModal'
          column={'isUtil'}
        />
      </td>
    )}
  </>
);

const AssessmentRow: React.FC<{
  item: IAssessmentResource;
  customFields?: ICustomField[];
  onCommentOpen: (c: string) => void;
  onImageOpen: (i: string[]) => void;
  onEdit?: (d: IAssessmentResource) => void;
  onDelete?: (d: IAssessmentResource) => void;
  onWeatherOpen: (w: IWeatherConditionResource) => void;
  lang?: TLang;
}> = ({
  item,
  customFields,
  onCommentOpen,
  onImageOpen,
  onEdit,
  onDelete,
  onWeatherOpen,
  lang,
}) => (
  <>
    <td className='ant-table-cell'>
      <span>{defaultDateFormat(item.assessment_date)}</span>
      <br /> <span>{item.user?.name ?? translate(lang, 'Unknown')}</span>
      {item.verifier && (
        <>
          <br />
          <span className='verifier-name'>
            {translate(lang, 'Verified by %s', item.verifier.name)}
          </span>
        </>
      )}
    </td>
    <td className='ant-table-cell'>{item.shell_size.min}</td>
    <td className='ant-table-cell'>{item.shell_size.max}</td>
    <td className='ant-table-cell'>{item.shell_size.avg}</td>
    {customFields?.map(x => (
      <td key={x.id} className='ant-table-cell'>
        {item.custom_values?.find(y => y.field_id === x.id)?.field_val}
      </td>
    ))}
    <td className='ant-table-cell'>
      {defaultDateFormat(item.planned_date_harvest)}
    </td>
    <td className='ant-table-cell'>
      {defaultDateFormat(item.ready_harvest, '-')}
    </td>
    <td className='ant-table-cell'>
      <div className='text-center'>{directionChar(item.direction)}</div>
    </td>
    <td className='ant-table-cell'>
      {!!item.comment && (
        <div
          className='btn__modal'
          onKeyDown={() => undefined}
          onClick={onCommentOpen.bind(this, item.comment)}
        >
          {translate(lang, 'View')}
        </div>
      )}
    </td>
    <td className='ant-table-cell'>
      {!item.images || item.images.length <= 0 ? (
        <span></span>
      ) : (
        <div
          className='btn__modal'
          onKeyDown={() => undefined}
          onClick={onImageOpen.bind(this, item.images)}
        >
          {translate(lang, 'View')}
        </div>
      )}
    </td>
    <td className='ant-table-cell'>
      {!item.weather_condition ? (
        <span></span>
      ) : (
        <div
          className='btn__modal'
          style={{ display: 'flex' }}
          onClick={onWeatherOpen.bind(this, item.weather_condition)}
        >
          <img
            className='ant-image-img'
            src={weather_icon}
            alt='weather'
            style={{ maxWidth: '20px' }}
          />
          <span style={{ marginLeft: '5px' }}>{translate(lang, 'View')}</span>
        </div>
      )}
    </td>
    {!!onEdit && !!onDelete && (
      <td className='ant-table-cell'>
        <DropdownMenu
          data={item}
          onEdit={onEdit}
          onDeleteRow={onDelete}
          type='isModal'
          column={'isUtil'}
        />
      </td>
    )}
  </>
);

const typeLabel: any = {
  VISUAL_ASSESSMENT: 'Visual Assessment',
  LINE_CLEANING: 'Line Cleaning',
  GENERAL_MAINTENANCE: 'General Maintenance',
};

const MaintenanceRow: React.FC<{
  item: IMaintenanceResource;
  colSpan: number;
  onImageOpen: (i: string[]) => void;
  onEdit?: (d: IMaintenanceResource) => void;
  onDelete?: (d: IMaintenanceResource) => void;
  onWeatherOpen: (w: IWeatherConditionResource) => void;
  lang?: TLang;
}> = ({
  item,
  colSpan,
  onImageOpen,
  onEdit,
  onDelete,
  onWeatherOpen,
  lang,
}) => (
  <>
    <td className='ant-table-cell'>
      <span>{defaultDateFormat(item.maintain_date)}</span>
      <br /> <span>{item.user?.name ?? translate(lang, 'Unknown')}</span>
      {item.verifier && (
        <>
          <br />
          <span className='verifier-name'>
            {translate(lang, 'Verified by %s', item.verifier.name)}
          </span>
        </>
      )}
    </td>
    <td className='ant-table-cell' colSpan={2}>
      {typeLabel[item.type]}
    </td>
    <td className='ant-table-cell' colSpan={colSpan}>
      {item.comment ?? translate(lang, 'No commented yet')}
    </td>
    <td className='ant-table-cell'>
      {!item.images || item.images.length <= 0 ? (
        <span></span>
      ) : (
        <div
          className='btn__modal'
          onKeyDown={() => undefined}
          onClick={onImageOpen.bind(this, item.images)}
        >
          {translate(lang, 'View')}
        </div>
      )}
    </td>
    <td className='ant-table-cell'>
      {!item.weather_condition ? (
        <span></span>
      ) : (
        <div
          className='btn__modal'
          style={{ display: 'flex' }}
          onClick={onWeatherOpen.bind(this, item.weather_condition)}
        >
          <img
            className='ant-image-img'
            src={weather_icon}
            alt='weather'
            style={{ maxWidth: '20px' }}
          />
          <span style={{ marginLeft: '5px' }}>{translate(lang, 'View')}</span>
        </div>
      )}
    </td>
    {onEdit && onDelete && (
      <td className='ant-table-cell'>
        <DropdownMenu
          data={item}
          onEdit={onEdit}
          onDeleteRow={onDelete}
          type='isModal'
          column={'isUtil'}
        />
      </td>
    )}
  </>
);

interface IRowData {
  type: 'assessment' | 'maintenance' | 'float' | 'seed_manage';
  value:
    | IAssessmentResource
    | IMaintenanceResource
    | ISeedInventoryResource
    | ISeedManage;
  date: number;
}

interface Props {
  type: TBusinessType;
  assessments?: IAssessmentResource[];
  maintenances?: IMaintenanceResource[];
  floats?: ISeedInventoryResource[];
  seed_manages?: ISeedManage[];
  loadLine?: () => void;
  lineData?: ILineResource;
  withTitle?: boolean;
}

const MaintenanceTable = ({
  type: businessType,
  assessments,
  maintenances,
  floats,
  seed_manages,
  loadLine,
  lineData,
  withTitle,
}: Props) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const customFields = SelectAssessFields(lineData?.farm_id);
  const isGrower = SelectIsGrower(lineData?.id);

  const [comment, setComment] = useState<string>();
  const [images, setImages] = useState<IImage[]>();
  const [mntData, setMntData] = useState<IMaintenanceResource>();
  const [assData, setAssData] = useState<IAssessmentResource>();
  const [fltData, setFltData] = useState<ISeedInventoryResource>();
  const [weather, setWeather] = useState<IWeatherConditionResource>();

  const openImages = (is: string[]) =>
    setImages(is.map(x => ({ original: x, thumbnail: x })));

  const deleteAssessment = async (d: IAssessmentResource) => {
    if (!window.confirm(translate(lang, '_delete_confirm', 'assessment')))
      return;
    const response = await sendSingleRequest(
      {},
      'DELETE',
      `api/farm/line/assessment/assessments/${d.id}`,
      true,
    );
    if (response.status) {
      if (loadLine) {
        await loadLine();
      }
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, '%s deleted successfully', 'Assessment'),
        }),
      );
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: translate(
            lang,
            response.data?.message ?? 'Failed to delete %s',
            'assessment',
          ),
        }),
      );
    }
  };
  const deleteMaintenance = async (d: IMaintenanceResource) => {
    if (!window.confirm(translate(lang, '_delete_confirm', 'maintenance')))
      return;
    const response = await sendSingleRequest(
      {},
      'DELETE',
      `api/farm/line/seed/maintenance/${d.id}`,
      true,
    );
    if (response.status) {
      if (loadLine) {
        await loadLine();
      }
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, '%s deleted successfully', 'Maintenance'),
        }),
      );
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: translate(
            lang,
            response.data?.message ?? 'Failed to delete %s',
            'maintenance',
          ),
        }),
      );
    }
  };
  const deleteFloating = async (d: ISeedInventoryResource) => {
    if (!window.confirm(translate(lang, '_delete_confirm', 'floating'))) return;
    const response = await sendSingleRequest(
      {},
      'DELETE',
      `api/inventory/inventory-seed/${d.id}`,
      true,
    );
    if (response.status) {
      if (loadLine) {
        await loadLine();
      }
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, '%s deleted successfully', 'Floating'),
        }),
      );
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: translate(
            lang,
            response.data?.message ?? 'Failed to delete %s',
            'floating',
          ),
        }),
      );
    }
  };

  const tableData = useMemo(() => {
    let data: IRowData[] = [];
    if (assessments) {
      data = data.concat(
        assessments.map(x => ({
          type: 'assessment',
          value: x,
          date: x.assessment_date,
        })),
      );
    }
    if (maintenances) {
      data = data.concat(
        maintenances.map(x => ({
          type: 'maintenance',
          value: x,
          date: x.maintain_date,
        })),
      );
    }
    if (floats) {
      data = data.concat(
        floats.map(x => ({
          type: 'float',
          value: x,
          date: x.manage_date,
        })),
      );
    }
    if (seed_manages) {
      data = data.concat(
        seed_manages.map(x => ({
          type: 'seed_manage',
          value: x,
          date: x.manage_date,
        })),
      );
    }
    data.sort((a, b) => (a.date > b.date ? -1 : 1));
    return data;
  }, [assessments, maintenances, floats, seed_manages]);

  const colSpan = businessType === 'MUSSEL' ? customFields.length + 5 : 5;

  return (
    <div style={{ overflow: 'auto' }}>
      <div
        className='ant-table-wrapper table table--isLine'
        style={{ width: '1200px', minWidth: '100%', maxWidth: '1200px' }}
      >
        {withTitle && (
          <Title
            className='ml-7 mb-17'
            size={6}
            color='black-3'
            align='default'
            fontWeight={700}
          >
            {translate(lang, 'Maintenances')}
          </Title>
        )}
        <div className='ant-table-container'>
          <table
            style={{ width: '530px', minWidth: '100%', tableLayout: 'auto' }}
          >
            <thead className='ant-table-thead'>
              <tr>
                <th className='ant-table-cell'>
                  {translate(lang, 'Date of assessment')}
                </th>
                <th className='ant-table-cell'>
                  {`${translate(lang, 'Size min')} (mm)`}
                </th>
                <th className='ant-table-cell'>
                  {`${translate(lang, 'Size max')} (mm)`}
                </th>
                <th className='ant-table-cell'>
                  {`${translate(lang, 'Size average')} (mm)`}
                </th>
                {businessType === 'MUSSEL' &&
                  customFields.map(x => (
                    <th key={x.id} className='ant-table-cell'>
                      {x.name} {x.unit ? ` (${x.unit})` : ''}
                    </th>
                  ))}
                <th className='ant-table-cell'>
                  {translate(lang, 'Planned harvest date')}
                </th>
                <th className='ant-table-cell'>
                  {translate(lang, 'Ready to harvest')}
                </th>
                <th className='ant-table-cell'>
                  {translate(lang, 'Direction')}
                </th>
                <th className='ant-table-cell'>{translate(lang, 'Comment')}</th>
                <th className='ant-table-cell'>{translate(lang, 'Photo')}</th>
                <th className='ant-table-cell'>{translate(lang, 'Weather')}</th>
                {!!lineData && (
                  <th
                    className='ant-table-cell'
                    style={{ textAlign: 'right' }}
                  ></th>
                )}
              </tr>
            </thead>
            <tbody className='ant-table-tbody'>
              {tableData.map((item, idx) => (
                <tr key={idx}>
                  {item.type === 'assessment' ? (
                    <AssessmentRow
                      item={item.value as IAssessmentResource}
                      customFields={
                        businessType === 'MUSSEL' ? customFields : undefined
                      }
                      onCommentOpen={setComment}
                      onImageOpen={openImages}
                      onEdit={lineData ? setAssData : undefined}
                      onDelete={lineData ? deleteAssessment : undefined}
                      onWeatherOpen={setWeather}
                      lang={lang}
                    />
                  ) : item.type === 'maintenance' ? (
                    <MaintenanceRow
                      item={item.value as IMaintenanceResource}
                      colSpan={colSpan}
                      onImageOpen={openImages}
                      onEdit={lineData ? setMntData : undefined}
                      onDelete={lineData ? deleteMaintenance : undefined}
                      onWeatherOpen={setWeather}
                      lang={lang}
                    />
                  ) : item.type === 'float' ? (
                    <FloatsRow
                      item={item.value as ISeedInventoryResource}
                      colSpan={colSpan}
                      onEdit={lineData ? setFltData : undefined}
                      onDelete={lineData ? deleteFloating : undefined}
                      onWeatherOpen={setWeather}
                      lang={lang}
                    />
                  ) : item.type === 'seed_manage' ? (
                    <SeedManageRow
                      item={item.value as ISeedManage}
                      lang={lang}
                    />
                  ) : (
                    <></>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <ModalComponent
        title={translate(lang, 'Comment')}
        text={translate(lang, comment ?? '')}
        visible={!!comment}
        onCancel={() => setComment(undefined)}
        type=''
      />
      {images !== undefined && (
        <Modal
          title={translate(lang, 'Photos')}
          centered
          visible={true}
          onOk={() => setImages(undefined)}
          onCancel={() => setImages(undefined)}
          width={1000}
        >
          <ImageGallery items={images} />
        </Modal>
      )}
      {!!mntData &&
        lineData &&
        (isGrower ? (
          <GrowerMaintenanceModal
            visible={true}
            maintenanceData={mntData}
            onClose={() => setMntData(undefined)}
            onConfirm={() => {
              setMntData(undefined);
              loadLine && loadLine();
            }}
            lineData={lineData}
            updateId={mntData.id}
          />
        ) : (
          <MaintenanceModal
            visible={true}
            title={'Update maintenance data'}
            onCancel={() => setMntData(undefined)}
            onConfirm={() => {
              setMntData(undefined);
              loadLine && loadLine();
            }}
            data={mntData}
            updateId={mntData.id}
            lineData={lineData}
          />
        ))}
      {!!assData && lineData && (
        <AssessmentModal
          type={businessType}
          visible={true}
          title='Edit assessment'
          data={assData}
          onCancel={() => setAssData(undefined)}
          onConfirm={() => {
            setAssData(undefined);
            loadLine && loadLine();
          }}
          lineData={lineData}
          updateID={assData.id}
        />
      )}
      {!!fltData && lineData && (
        <FloatManageModal
          visible={true}
          title='Update floating'
          data={fltData}
          onCancel={() => setFltData(undefined)}
          lineData={lineData}
          onConfirm={() => {
            setFltData(undefined);
            loadLine && loadLine();
          }}
          updateId={fltData.id}
        />
      )}
      {!!weather && (
        <WeatherInfoModal
          data={weather}
          visible={true}
          title='Weather Condition'
          onClose={() => setWeather(undefined)}
        />
      )}
    </div>
  );
};

export default MaintenanceTable;
