import { Modal } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendSingleRequest } from '../../../apis';
import { IMusselLineResource } from '../../../entities/farms.entities';
import { showFeedback } from '../../../store/ui/ui.actions';
import { parseImageUrlToFileItem } from '../../../util/getBase64';
import { toMillisecond, toSecond } from '../../../util/toggleSecondMillisecond';
import {
  Button,
  CheckboxButton,
  CloseIcon,
  Datepicker,
  Dropdown,
  Input,
  Paragrapgh,
  Spinner,
  Subtitle,
} from '../../shared';
import ModalFeedbackView from '../../shared/feedback/ModalFeedbackView';
import TimeTrackInput, {
  toSecTimeTracks,
} from '../../shared/input/TimeTrackInput';
import PicturesWall from '../../shared/pictures-wall/PicturesWall';
import { ICustomValue } from '../../../entities/automation.entities';
import { Link } from 'react-router-dom';
import { loadFarmsData } from '../../../store/farms/farms.actions';
import { ITimeRange } from '../../../entities/general.entities';
import { selectLang } from '../../../store/ui/ui.selector';
import { translate } from '../../../lib/lang.helper';
import {
  SelectAssessFields,
  SelectMusselFarmsByFarmID,
} from '../../../store/extra/extra.selector';
import { getEstHarvestDate } from '../../../lib/farm.helpers';
import BatchAssessView from './BatchAssessView';
import DirectionForm from '../../farm-util/DirectionForm';

interface IFormData {
  size_min: number | null;
  size_max: number | null;
  size_avg: number | null;
  btm_size_min?: number | null;
  btm_size_max?: number | null;
  btm_size_avg?: number | null;
  assessment_date: number;
  planned_date_harvest: number;
  ready_to_harvest: boolean;
  comment: string | null;
  origin_spat_id?: number | null;
  direction: string | null;
  time_tracks?: ITimeRange[];
  images: any[];
  custom_values?: ICustomValue[];
}

const defaultForm: IFormData = {
  size_min: null,
  size_max: null,
  size_avg: null,
  btm_size_min: null,
  btm_size_max: null,
  btm_size_avg: null,
  planned_date_harvest: moment().toDate().getTime(),
  assessment_date: moment().toDate().getTime(),
  comment: null,
  ready_to_harvest: false,
  direction: null,
  time_tracks: [],
  images: [],
};

const getPrevPlannedDateHarvest = (line: IMusselLineResource) => {
  const lastDate = getEstHarvestDate(line);
  return lastDate ? toMillisecond(lastDate) : defaultForm.planned_date_harvest;
};

interface Props {
  visible: boolean;
  title?: string;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  data?: any;
  lineData: IMusselLineResource;
  updateID?: number;
  onlyView?: boolean;
  overConfirm?: (data: any) => void;
  lineChangeable?: boolean;
}

const MusselAssessModal = ({
  visible,
  title,
  onCancel,
  onConfirm,
  data,
  lineData,
  updateID,
  onlyView,
  overConfirm,
  lineChangeable,
}: Props) => {
  const dispatch = useDispatch<any>();

  const farmsData = SelectMusselFarmsByFarmID(lineData.farm_id);
  const customFields = SelectAssessFields(lineData.farm_id);
  const lang = useSelector(selectLang);

  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState<IFormData>({
    ...defaultForm,
    planned_date_harvest: getPrevPlannedDateHarvest(lineData),
  });
  const [warning, setWaring] = useState(false);
  const [errors, setErrors] = useState<{
    min: string | null;
    max: string | null;
    avg: string | null;
  }>({
    min: null,
    max: null,
    avg: null,
  });
  const [focFarmId, setFocFarmId] = useState(lineData.farm_id);
  const [curLine, setCurLine] = useState(lineData);
  const [trackOrigin, setTrackOrigin] = useState(!!data?.origin_spat_id);
  const [assessBottom, setAssessBottom] = useState(!!data?.btm_size_avg);
  const [customValues, setCustomValues] = useState(
    customFields.map(x => {
      const d =
        data && data.custom_values
          ? data.custom_values.find((y: ICustomValue) => y.field_id === x.id)
          : null;
      return {
        input_type: x.input_type,
        field_label: x.name,
        options: x.options
          ?.split(',')
          .map(t => ({ id: t, label: t, value: t })),
        field_id: x.id,
        field_val: d?.field_val ?? '',
        data_type: x.data_type,
        unit: x.unit,
      };
    }),
  );

  const handleLineChange = (v: string) => {
    const farm = farmsData.find(x => x.id === focFarmId);
    if (!farm) return;
    const line = farm.lines.find(x => x.id === Number(v));
    if (!line?.growing_cycle) return;
    setCurLine(line);
    setFormData(prv => ({
      ...prv,
      planned_date_harvest: toMillisecond(
        line.growing_cycle?.main_seed.planned_date_harvest ?? 0,
      ),
    }));
  };
  const updateForm = (key: keyof IFormData, val: any) => {
    if (
      [
        'size_min',
        'size_max',
        'size_avg',
        'btm_size_min',
        'btm_size_max',
        'btm_size_avg',
      ].includes(key)
    ) {
      val = val.length <= 0 ? null : Number(val);
    }
    let tmp = { ...formData, [key]: val };
    if (key === 'size_min') {
      setErrors(p => ({ ...p, min: null, avg: null }));
      tmp.size_avg = Math.round(
        (Number(tmp.size_max) + Number(tmp.size_min)) / 2,
      );
    } else if (key === 'size_max') {
      setErrors(p => ({ ...p, max: null, avg: null }));
      tmp.size_avg = Math.round(
        (Number(tmp.size_max) + Number(tmp.size_min)) / 2,
      );
    } else if (key === 'btm_size_min' || key === 'btm_size_max') {
      tmp.btm_size_avg = Math.round(
        (Number(tmp.btm_size_max) + Number(tmp.btm_size_min)) / 2,
      );
    }
    setFormData(tmp);
  };
  const showError = (message: string) =>
    dispatch(
      showFeedback({
        isMessageModal: true,
        type: 'error',
        message: translate(lang, message),
      }),
    );

  const validForm = () => {
    let res: any = true;
    let tmpErr = { ...errors };
    if (!formData.size_min) {
      tmpErr.min = translate(lang, 'Required field');
      res = false;
    }
    if (!formData.size_max) {
      tmpErr.max = translate(lang, 'Required field');
      res = false;
    }
    if (!formData.size_avg) {
      tmpErr.avg = translate(lang, 'Required field');
      res = false;
    }
    if (Number(formData.size_min) > Number(formData.size_max)) {
      tmpErr.avg = translate(
        lang,
        'Maximum value should be equal or greater than minimum value',
      );
      res = false;
    }
    if (
      formData.time_tracks &&
      toSecTimeTracks(formData.time_tracks) === false
    ) {
      showError('_invalid_time_range');
      res = false;
    }
    setErrors(tmpErr);
    if (!res) return null;

    res = {
      ...formData,
      assessment_date: toSecond(formData.assessment_date),
      planned_date_harvest: toSecond(formData.planned_date_harvest),
      seeding_id: curLine.growing_cycle?.main_seed.id,
      line_id: curLine.id,
    };
    if (assessBottom) {
      if (
        !formData.btm_size_avg ||
        !formData.btm_size_max ||
        !formData.btm_size_min
      ) {
        showError('Bottom size measuring can not be empty');
        return null;
      }
      if (formData.btm_size_max < formData.btm_size_min) {
        showError('Bottom size measuring range is incorrect');
        return null;
      }
    } else {
      res.btm_size_min = res.btm_size_max = res.btm_size_avg = undefined;
    }

    if (res.time_tracks && res.time_tracks.length > 0) {
      const tt = toSecTimeTracks(res.time_tracks);
      if (!tt) {
        showError('Invalid time tracks information');
        return null;
      }
      res.time_tracks = tt;
    }
    const cvs = customValues
      .filter(x => !!x.field_val)
      .map(({ field_id, field_val }) => ({ field_id, field_val }));
    if (cvs.length > 0) {
      res.custom_values = cvs;
    } else {
      res.custom_values = undefined;
    }
    return res;
  };
  const handleConfirmClick = async () => {
    if (onlyView) return;
    const form = validForm();
    if (!form) return;

    if (
      !warning &&
      curLine.growing_cycle?.last_assessment &&
      (curLine.growing_cycle.last_assessment.shell_size.min >= form.size_min ||
        curLine.growing_cycle.last_assessment.shell_size.max >= form.size_max)
    ) {
      setWaring(true);
      return;
    }
    setDisabled(true);
    if (overConfirm) {
      overConfirm(form);
    } else {
      const res = !updateID
        ? await sendSingleRequest(
            form,
            'POST',
            'api/farm/line/assessment/assessments',
            true,
          )
        : await sendSingleRequest(
            form,
            'PUT',
            `api/farm/line/assessment/assessments/${updateID}`,
            true,
          );
      if (res.status) {
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'success',
            message: translate(lang, res.data?.message ?? 'Success'),
          }),
        );
        dispatch(loadFarmsData());
        onConfirm(res.data);
      } else {
        showError(res.data?.message ?? 'Request failed');
        setDisabled(false);
      }
    }
  };
  const updateCustomValue = (fieldID: number, val: string) => {
    let tmp = [...customValues];
    const i = tmp.findIndex(x => x.field_id === fieldID);
    if (i >= 0) {
      tmp[i].field_val = val;
    }
    setCustomValues(tmp);
  };

  useEffect(() => {
    if (data) {
      let form = { ...data };
      if (form.assessment_date)
        form.assessment_date = toMillisecond(form.assessment_date);
      if (form.planned_date_harvest)
        form.planned_date_harvest = toMillisecond(form.planned_date_harvest);
      form.ready_to_harvest = !!data.ready_harvest || !!data.ready_to_harvest;
      if (form.time_tracks && form.time_tracks.length > 0) {
        form.time_tracks = data.time_tracks.map((x: any) => ({
          start_time: toMillisecond(x.start_time),
          finish_time: toMillisecond(x.finish_time),
        }));
      }
      if (data.images && data.images.length > 0) {
        let tmp: any = [];
        for (let x of data.images) {
          if (typeof x === 'object' && x !== null) {
            tmp.push(x);
          } else {
            tmp.push(parseImageUrlToFileItem(x));
          }
        }
        if (tmp && tmp.length > 0) {
          form.images = tmp;
        }
      }
      setFormData(prv => ({ ...prv, ...form }));
    }
  }, [data]);

  const farmOptions = farmsData.map(x => ({
    id: x.id.toString(),
    value: x.id.toString(),
    label: `${x.name} - ${x.farm_number}`,
  }));
  const lineOptions = farmsData
    .find(x => x.id === focFarmId)
    ?.lines.filter(x => !!x.growing_cycle)
    .map(x => ({
      id: x.id.toString(),
      value: x.id.toString(),
      label: x.line_name,
    }));

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
      width={650}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, title ?? 'Mussel Assessment')}
          </Subtitle>
        </div>
        <div className='assessment-modal'>
          {lineChangeable && (
            <div className='mb-17 d-flex'>
              <div className='w-100 mr-7'>
                <Dropdown
                  label={translate(lang, 'Select farm')}
                  options={farmOptions}
                  value={focFarmId.toString()}
                  onChange={v => setFocFarmId(Number(v))}
                  disabled={onlyView}
                />
              </div>
              <div className='w-100 ml-7'>
                <Dropdown
                  label={translate(lang, 'Select line')}
                  options={lineOptions ?? []}
                  value={
                    !!lineOptions &&
                    lineOptions.some(x => Number(x.id) === curLine.id)
                      ? curLine.id.toString()
                      : curLine.line_name
                  }
                  onChange={handleLineChange}
                  disabled={onlyView}
                />
              </div>
            </div>
          )}
          {warning ? (
            <div className='mt-32 mb-64 pb-32 pt-17 pl-17 pr-17'>
              <Paragrapgh
                size={1}
                fontWeight={400}
                color='black-2'
                align='default'
              >
                {translate(lang, '_assessment_warning')}
              </Paragrapgh>
            </div>
          ) : (
            <div className='assessment-modal'>
              <div className='assessment-modal__condition d-flex justify-content-between'>
                <div className={`mx-180 ${errors.min ? 'invalid-form' : ''}`}>
                  <Input
                    type='number'
                    label={`${translate(
                      lang,
                      assessBottom ? 'Top size min' : 'Size min',
                    )} (mm)`}
                    value={formData.size_min?.toString() ?? ''}
                    onChange={e => updateForm('size_min', e.target.value)}
                    required={true}
                    disabled={onlyView}
                  />
                  {errors.min && (
                    <div className='invalid-feedback'>{errors.min}</div>
                  )}
                </div>
                <div className={`mx-180 ${errors.max ? 'invalid-form' : ''}`}>
                  <Input
                    type='number'
                    label={`${translate(
                      lang,
                      assessBottom ? 'Top size max' : 'Size max',
                    )} (mm)`}
                    value={formData.size_max?.toString() ?? ''}
                    onChange={e => updateForm('size_max', e.target.value)}
                    required={true}
                    disabled={onlyView}
                  />
                  {errors.max && (
                    <div className='invalid-feedback'>{errors.max}</div>
                  )}
                </div>
                <div className={`mx-180 ${errors.avg ? 'invalid-form' : ''}`}>
                  <Input
                    type='number'
                    label={`${translate(
                      lang,
                      assessBottom ? 'Top size average' : 'Size average',
                    )} (mm)`}
                    value={formData.size_avg?.toString() ?? ''}
                    onChange={e => updateForm('size_avg', e.target.value)}
                    required={true}
                    disabled={onlyView}
                  />
                  {errors.avg && (
                    <div className='invalid-feedback'>{errors.avg}</div>
                  )}
                </div>
              </div>
              <div className='mb-17'>
                <CheckboxButton
                  label={translate(lang, 'Measure top/bottom separately')}
                  checked={assessBottom}
                  onChange={e => setAssessBottom(e.target.checked)}
                />
              </div>
              {assessBottom && (
                <div className='assessment-modal__condition d-flex justify-content-between'>
                  <div className='mx-180'>
                    <Input
                      type='number'
                      label={`${translate(lang, 'Bottom size min')} (mm)`}
                      value={formData.btm_size_min?.toString() ?? ''}
                      onChange={e => updateForm('btm_size_min', e.target.value)}
                      required={true}
                      disabled={onlyView}
                    />
                  </div>
                  <div className='mx-180'>
                    <Input
                      type='number'
                      label={`${translate(lang, 'Bottom size max')} (mm)`}
                      value={formData.btm_size_max?.toString() ?? ''}
                      onChange={e => updateForm('btm_size_max', e.target.value)}
                      required={true}
                      disabled={onlyView}
                    />
                  </div>
                  <div className='mx-180'>
                    <Input
                      type='number'
                      label={`${translate(lang, 'Bottom size average')} (mm)`}
                      value={formData.btm_size_avg?.toString() ?? ''}
                      onChange={e => updateForm('btm_size_avg', e.target.value)}
                      required={true}
                      disabled={onlyView}
                    />
                  </div>
                </div>
              )}
              <Datepicker
                className='mb-17'
                defaultValue={formData.assessment_date}
                label={translate(lang, 'Assessment date')}
                onChange={e =>
                  updateForm(
                    'assessment_date',
                    e!.toDate().getTime() <
                      Number(curLine.growing_cycle?.main_seed.planned_date_seed)
                      ? moment().toDate().getTime()
                      : e!.toDate().getTime(),
                  )
                }
                required={true}
                disabled={onlyView}
              />
              <Datepicker
                className='mb-17'
                defaultValue={formData.planned_date_harvest}
                label={translate(lang, 'Planned harvest date')}
                onChange={e =>
                  updateForm(
                    'planned_date_harvest',
                    e!.toDate().getTime() <
                      Number(curLine.growing_cycle?.main_seed.planned_date_seed)
                      ? moment().toDate().getTime()
                      : e!.toDate().getTime(),
                  )
                }
                required={true}
                disabled={onlyView}
              />
              {curLine.growing_cycle && (
                <BatchAssessView
                  status={trackOrigin}
                  onChange={setTrackOrigin}
                  lineID={curLine.id}
                  farmID={curLine.farm_id}
                  trackSeedingID={curLine.growing_cycle.main_seed.id}
                  batchID={formData.origin_spat_id ?? undefined}
                  onBatchSelect={v => updateForm('origin_spat_id', v)}
                />
              )}
              <CheckboxButton
                className='mb-17'
                label={translate(lang, 'Ready to harvest')}
                checked={formData.ready_to_harvest}
                onChange={e => updateForm('ready_to_harvest', e.target.checked)}
                disabled={onlyView}
              />
              {customValues.map(x => (
                <div key={x.field_id} className='mb-17'>
                  {x.input_type === 'select' ? (
                    <>
                      <Dropdown
                        label={x.field_label}
                        options={x.options ?? []}
                        value={x.field_val}
                        onChange={v => updateCustomValue(x.field_id, v)}
                        disabled={onlyView}
                      />
                      {(!x.options || x.options.length <= 0) && (
                        <Link to={'/custom-fields'}>
                          <span style={{ color: '#008ed1' }}>Set up field</span>
                        </Link>
                      )}
                    </>
                  ) : (
                    <Input
                      type={x.data_type === 'number' ? 'number' : 'text'}
                      label={x.field_label}
                      value={x.field_val ?? ''}
                      onChange={e =>
                        updateCustomValue(x.field_id, e.target.value)
                      }
                      unit={x.unit}
                      disabled={onlyView}
                    />
                  )}
                </div>
              ))}
              <DirectionForm
                value={formData.direction}
                onChange={v => updateForm('direction', v)}
              />
              {formData.time_tracks && (
                <TimeTrackInput
                  data={formData.time_tracks}
                  onChange={t => updateForm('time_tracks', t)}
                  disabled={onlyView}
                />
              )}
              <Input
                label={translate(lang, 'Comment')}
                placeholder={`${translate(lang, 'Type your comment')}...`}
                className='mb-17'
                type='textarea'
                value={formData.comment ?? ''}
                dataType='comment'
                onChange={e => updateForm('comment', e.target.value)}
                isOptional={true}
                disabled={onlyView}
              />
              {!!formData.images && (
                <PicturesWall
                  label={translate(lang, 'Images')}
                  fileList={formData.images}
                  handleChangeImages={f => updateForm('images', f)}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        {!warning ? (
          <Button
            width={'small'}
            size={2}
            type='fill'
            color='green'
            className='rsp-btn ml-16'
            onClick={handleConfirmClick}
            disabled={disabled || onlyView}
          >
            {translate(lang, 'Confirm')}
          </Button>
        ) : (
          <>
            <Button
              className='rsp-btn'
              width='small'
              size={2}
              type='fill'
              color='blue'
              onClick={() => setWaring(false)}
              disabled={disabled}
            >
              {translate(lang, 'Let me correct')}
            </Button>
            <Button
              width='small'
              size={2}
              type='fill'
              color='green'
              className='rsp-btn ml-16'
              onClick={handleConfirmClick}
              disabled={disabled}
            >
              {translate(lang, "Yes, it's valid")}
            </Button>
          </>
        )}
      </div>
      <ModalFeedbackView />
    </Modal>
  );
};

export default MusselAssessModal;
