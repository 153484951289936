import React, { useMemo, useState } from 'react';
import { Modal, Tooltip } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ISpatStorageResource } from '../../entities/farms.entities';
import { Button, CloseIcon, MinusIcon, PlusIcon, Subtitle } from '../shared';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { TActionType, TBusinessType } from '../../entities/general.entities';
import {
  IHarvestResource,
  IOysterHarvest,
  ISeedingResource,
} from '../../entities/growing.entities';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { TLang } from '../../entities/ui.entities';
import './styles.scss';

const seedPropLabel = (d: any) => {
  return d.line_length
    ? `Seeding ${d.line_length} m`
    : `Seeding ${d.basket_count} ${d.line.farming_method}`;
};

const spatWeightLabel = (d: ISpatStorageResource, available = 0) =>
  `${
    available === 0
      ? d.weight
      : available === 1
      ? d.available_weight
      : d.weight - (d.available_weight ?? 0)
  } ${d.type === 'MUSSEL' ? 'kg' : d.type === 'OYSTER' ? 'pcs' : 'kg'}`;

const calcDiff = (from: number, to: number) => {
  let m = moment(to * 1000).diff(from * 1000, 'months');
  if (m !== 0) {
    return `${m > 0 ? '+' : ''}${m} month${Math.abs(m) === 1 ? '' : 's'}`;
  }
  m = moment(to * 1000).diff(from * 1000, 'days');
  return `${m > 0 ? '+' : ''}${m} day${Math.abs(m) === 1 ? '' : 's'}`;
};

interface INode {
  content: string | React.ReactElement;
  onClick?: (d: any) => void;
  children?: INode[];
  date: number;
}

const NodeDiv: React.FC<{ node: INode; parentDate?: number }> = ({
  node,
  parentDate,
}) => (
  <div className='--node'>
    <div className='--line-bar'>
      <div className='--left-line' />
      <div className='--vert-line'></div>
      <div className='--right-line' />
      {!!parentDate && (
        <div className='--edge-info'>
          <span>{calcDiff(parentDate, node.date)}</span>
        </div>
      )}
    </div>
    {!!node.children && node.children.length > 0 ? (
      <>
        <div className='--parent'>
          <div className='--box'>{node.content}</div>
          <div className='--vert-line' />
        </div>
        <div className='--children'>
          {node.children.map((x, i) => (
            <NodeDiv key={i} node={x} parentDate={node.date} />
          ))}
        </div>
      </>
    ) : (
      <div className='--box'>{node.content}</div>
    )}
  </div>
);

interface IJourneyData {
  type: TActionType;
  data: any;
  children?: IJourneyData[];
}

const makeNode = (
  j: IJourneyData,
  farms: any[],
  leafCallback: () => void,
  type: TBusinessType,
  lang: TLang | undefined,
  lineID?: number,
): INode => {
  if (
    !j.children ||
    j.children.filter(x => ['SEEDING', 'HARVEST'].includes(x.type)).length <= 0
  ) {
    leafCallback();
  }
  if (j.type === 'SEEDING') {
    const ac = j.children?.filter(x => x.type === 'ASSESSMENT').length;
    const mc = j.children?.filter(x => x.type === 'MAINTENANCE').length;
    const fc = j.children?.filter(x => x.type === 'INVENTORY_SEED').length;
    const farm = farms.find(x => x.id === j.data.line.farm_id);

    return {
      content: (
        <div
          className={`--content --seeding ${
            lineID === j.data.line.id ? '--focus' : ''
          }`}
        >
          <div>{`${farm?.name} - ${j.data.line.line_name}`}</div>
          {!!j.data.is_catch_spat && <div>{translate(lang, 'Catch Spat')}</div>}
          <div>
            {!j.data.spat_size_max
              ? `${translate(lang, 'Size')} ${j.data.spat_size}mm`
              : `${translate(lang, 'Size')} ${j.data.spat_size}mm - ${
                  j.data.spat_size_max
                }mm`}
          </div>
          <div>{seedPropLabel(j.data)}</div>
          {!!ac && (
            <div className='--assessment'>{`${ac} ${translate(
              lang,
              'assessments',
            )}`}</div>
          )}
          {!!mc && (
            <div className='--maintenance'>{`${mc} ${translate(
              lang,
              'maintenances',
            )}`}</div>
          )}
          {!!fc && (
            <div className='--floats-manage'>{`${fc} ${translate(
              lang,
              'floats managements',
            )}`}</div>
          )}
        </div>
      ),
      children: j.children
        ?.filter(x => x.type === 'HARVEST')
        .map(x => makeNode(x, farms, leafCallback, type, lang, lineID)),
      date: j.data.planned_date_seed,
    };
  } else if (j.type === 'HARVEST') {
    const farm = farms.find(x => x.id === j.data.line.farm_id);
    return {
      content: (
        <div
          className={`--content --harvest ${
            lineID === j.data.line.id ? '--focus' : ''
          }`}
        >
          <div>{`${farm?.name} - ${j.data.line.line_name}`}</div>
          <div>
            {!j.data.shell_length_max
              ? `${translate(lang, 'Size')} ${j.data.shell_length ?? 0} mm`
              : `${translate(lang, 'Size')} ${j.data.shell_length}mm - ${
                  j.data.shell_length_max
                }mm`}
          </div>
          {type === 'MUSSEL' ? (
            <div>{`${translate(lang, 'Harvest')} ${j.data.amount} kg / ${
              j.data.line_length
            } m`}</div>
          ) : type === 'OYSTER' ? (
            <div>{`${translate(lang, 'Harvest')} ${j.data.amount_dz} dz`}</div>
          ) : (
            <></>
          )}
        </div>
      ),
      children: j.children?.map(x =>
        makeNode(x, farms, leafCallback, type, lang, lineID),
      ),
      date: j.data.complete_date,
    };
  } else {
    return { content: 'Other Item', date: 0 };
  }
};

interface Props {
  data: {
    parent_seeding?: ISeedingResource | null;
    parent_harvest?: IHarvestResource;
    data: IJourneyData[];
    spat_storage: ISpatStorageResource;
  };
  visible: boolean;
  onClose: () => void;
  focusLineID?: number;
}

const SpatJourneyModal = ({
  data,
  visible,
  onClose,
  focusLineID: lineID,
}: Props) => {
  const farmsData = useSelector(selectFarmsData);
  const lang = useSelector(selectLang);

  const [zoom, setZoom] = useState(1);

  const onNodeViewWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    if (!e.shiftKey) return;
    const z = e.deltaY < 0 ? zoom + 0.1 : zoom - 0.1;
    setZoom(Math.max(0.1, z));
  };

  const { root, leafs } = useMemo(() => {
    const spat = data.spat_storage;
    let leafs = 0;
    let r: INode = {
      content: (
        <div className='--content --other'>
          <div>{translate(lang, 'Manually added')}</div>
          <div>{`${spat.source} - ${spatWeightLabel(spat)}`}</div>
          <div>
            {`${translate(lang, 'Seeded')} ${spatWeightLabel(spat, 2)}`}
          </div>
          <div>
            {`${translate(lang, 'Available')} ${spatWeightLabel(spat, 1)}`}
          </div>
          {!!spat.seed_type && (
            <div>{`${translate(lang, 'Spat type')}: ${spat.seed_type}`}</div>
          )}
        </div>
      ),
      children: [],
      date: spat.collection_date,
    };
    if (data.parent_harvest) {
      const d = data.parent_harvest;
      const farm = farmsData.find(x => x.id === d.line.farm_id);
      r.content = (
        <div
          className={`--content --harvest ${
            lineID === d.line.id ? '--focus' : ''
          }`}
        >
          <div>{`${farm?.name} - ${d.line.line_name}`}</div>
          <div>
            {!d.shell_length_max
              ? `${translate(lang, 'Size')} ${d.shell_length ?? 0} mm`
              : `${translate(lang, 'Size')} ${d.shell_length}mm - ${
                  d.shell_length_max
                }mm`}
          </div>
          {spat.type === 'MUSSEL' ? (
            <div>
              {`${translate(lang, 'Harvest')} ${d.amount} kg / ${
                d.line_length
              } m`}
            </div>
          ) : spat.type === 'OYSTER' ? (
            <div>
              {`${translate(lang, 'Harvest')} ${
                (d as IOysterHarvest).amount_dz
              } dz`}
            </div>
          ) : (
            <></>
          )}
        </div>
      );
      r.date = d.complete_date;
      if (data.parent_seeding) {
        const d = data.parent_seeding;
        const farm = farmsData.find(x => x.id === d.line.farm_id);
        r = {
          content: (
            <div
              className={`--content --seeding ${
                lineID === data.parent_seeding.id ? '--focus' : ''
              }`}
            >
              <div>{`${farm?.name} - ${d.line.line_name}`}</div>
              {!!d.is_catch_spat && <div>{translate(lang, 'Catch Spat')}</div>}
              <div>
                {!d.spat_size_max
                  ? `${translate(lang, 'Size')} ${d.spat_size}mm`
                  : `${translate(lang, 'Size')} ${d.spat_size}mm - ${
                      d.spat_size_max
                    }mm`}
              </div>
              <div>{seedPropLabel(d)}</div>
            </div>
          ),
          children: [r],
          date: d.planned_date_seed,
        };
      }
    }
    if (r.children && r.children.length > 0) {
      r.children[0].children = data.data.map(x =>
        makeNode(
          x,
          farmsData,
          () => (leafs = leafs + 1),
          spat.type,
          lang,
          lineID,
        ),
      );
    } else {
      r.children = data.data.map(x =>
        makeNode(
          x,
          farmsData,
          () => (leafs = leafs + 1),
          spat.type,
          lang,
          lineID,
        ),
      );
    }
    leafs = Math.max(1, leafs);
    return { root: r, leafs };
  }, [data, farmsData, lineID]);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      closable
      closeIcon={<CloseIcon />}
      width='full'
      style={{ maxWidth: 1440 }}
      footer={null}
    >
      <div className='wrap view-modal spats-tree'>
        <div className='d-flex align-items-center mb-16'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, 'Spat Storage Journey')}
          </Subtitle>
        </div>
        <div className='d-flex align-items-center mb-16'>
          <Tooltip
            className='d-flex align-items-center'
            title='Use Shift + Scroll to zoom'
          >
            <div
              className='zoom-icon-btn'
              onClick={() => setZoom(Math.max(0.1, zoom - 0.1))}
            >
              <MinusIcon />
            </div>
            <div className='ml-7 mr-7'>
              <Subtitle
                color='black-2'
                align='center'
                size={0}
                fontWeight={600}
              >
                {zoom.toFixed(1)}
              </Subtitle>
            </div>
            <div className='zoom-icon-btn' onClick={() => setZoom(zoom + 0.1)}>
              <PlusIcon />
            </div>
          </Tooltip>
        </div>
        <div className='pt-16 pb-24'>
          <div className='node-view' onWheel={onNodeViewWheel} style={{ zoom }}>
            <NodeDiv node={root} />
          </div>
        </div>
        <div className='modal-button d-flex justify-content-end mt-32'>
          <Button
            width={'small'}
            size={2}
            type='fill'
            color='green'
            className='rsp-btn ml-16'
            onClick={onClose}
          >
            {translate(lang, 'Close')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SpatJourneyModal;
