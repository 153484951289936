import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Modal, Radio } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  IFloatSum,
  IMusselFarm,
  IMusselLineDetail,
  IMusselLineResource,
} from '../../../entities/farms.entities';
import toggleSecondMillisecond, {
  defaultDateFormat,
} from '../../../util/toggleSecondMillisecond';
import {
  Button,
  CheckboxButton,
  CloseIcon,
  Datepicker,
  DollarIcon,
  Dropdown,
  Feedback,
  Input,
  Paragrapgh,
  RadioButton,
  Spinner,
  Subtitle,
  Title,
} from '../../shared';
import TimeTrackInput, {
  toSecTimeTracks,
} from '../../shared/input/TimeTrackInput';
import PicturesWall from '../../shared/pictures-wall/PicturesWall';
import SignatureCanvas from 'react-signature-canvas';
import { parseImageUrlToFileItem } from '../../../util/getBase64';
import { showFeedback } from '../../../store/ui/ui.actions';
import { sendSingleRequest } from '../../../apis';
import { isEmailFormat } from '../../../util/validation';
import { loadFarmsData } from '../../../store/farms/farms.actions';
import { ITimeRange } from '../../../entities/general.entities';
import { loadInventories } from '../../../store/inventories/inventories.actions';
import { selectLang } from '../../../store/ui/ui.selector';
import { componentTranslate, translate } from '../../../lib/lang.helper';
import { ICustomValue } from '../../../entities/automation.entities';
import { Link } from 'react-router-dom';
import {
  SelectHarvestFields,
  SelectInventoriesByFarmID,
  SelectIsBudgetVisible,
  SelectMusselFarmsByFarmID,
  SelectProfileByFarmID,
  SelectSettingsByFarmID,
  SelectUserMeta,
  SelectUtilsByFarmID,
} from '../../../store/extra/extra.selector';
import DirectionForm from '../../farm-util/DirectionForm';

interface IHarvestSpat {
  spat_storage_id: number;
  amount: number | null;
  line_length: number | null;
}

interface IReseed {
  lines: Array<{
    line_id: number;
    line_length?: number;
  }>;
  density?: number;
  spacing?: number;
  submersion?: number;
  drop?: number;
}

interface ReseedProps {
  farmsData: IMusselFarm[];
  farm?: IMusselFarm;
  setFarm: (f: IMusselFarm | undefined) => void;
  form: IReseed;
  setForm: (f: IReseed) => void;
}

const ReseedForm = ({
  farmsData,
  farm,
  setFarm,
  form,
  setForm,
}: ReseedProps) => {
  const lang = useSelector(selectLang);
  const selectLines = (v: string[]) => {
    setForm({ ...form, lines: v.map(x => ({ line_id: Number(x) })) });
  };
  const updateLine = (line_id: number, val: string) => {
    let tmp = [...form.lines];
    const i = tmp.findIndex(x => x.line_id === line_id);
    if (i >= 0) {
      tmp[i].line_length = val.length > 0 ? Number(val) : undefined;
    }
    setForm({ ...form, lines: tmp });
  };
  const updateForm = (key: keyof IReseed, value: any) => {
    value = value.length <= 0 ? undefined : Number(value);
    setForm({ ...form, [key]: value });
  };

  return (
    <div className='mb-24'>
      <div className='mb-12'>
        <Dropdown
          label={translate(lang, 'Select farm')}
          options={farmsData.map(x => ({
            id: x.id.toString(),
            value: x.id.toString(),
            label: `${x.name} - ${x.farm_number}`,
          }))}
          value={farm?.id.toString() ?? ''}
          onChange={(v: string) =>
            setFarm(farmsData.find(x => x.id === Number(v)))
          }
        />
      </div>
      <div className='mb-12'>
        <Dropdown
          mode='multiple'
          label={translate(lang, 'Select lines')}
          options={
            farm?.lines.map(x => ({
              id: x.id.toString(),
              value: x.id.toString(),
              label: x.line_name,
            })) ?? []
          }
          value={form.lines.map(x => x.line_id.toString()) as any}
          onChange={(v: any) => selectLines(v)}
        />
      </div>
      <div className='d-flex mb-17' style={{ columnGap: 15 }}>
        {form.lines.map(x => (
          <div key={x.line_id} style={{ flexBasis: '30%' }}>
            <Input
              label={`Length of Line ${
                farm?.lines.find(y => y.id === x.line_id)?.line_name
              }`}
              value={x.line_length?.toString() ?? ''}
              onChange={e => updateLine(x.line_id, e.target.value)}
              type='number'
              unit='m'
            />
          </div>
        ))}
      </div>
      <div className='d-flex mb-7'>
        <div className='w-100 mr-7'>
          <Input
            label={translate(lang, 'Density')}
            value={form.density?.toString() ?? ''}
            onChange={e => updateForm('density', e.target.value)}
            type='number'
            unit='spat per m'
          />
        </div>
        <div className='w-100 ml-7'>
          <Input
            label={translate(lang, 'Spacing')}
            value={form.spacing?.toString() ?? ''}
            onChange={e => updateForm('spacing', e.target.value)}
            type='number'
            unit='mm'
          />
        </div>
      </div>
      <div className='d-flex mb-17'>
        <div className='w-100 mr-7'>
          <Input
            label={translate(lang, 'Submersion')}
            value={form.submersion?.toString() ?? ''}
            onChange={e => updateForm('submersion', e.target.value)}
            type='number'
            unit='m'
          />
        </div>
        <div className='w-100 ml-7'>
          <Input
            label={translate(lang, 'Drop')}
            value={form.drop?.toString() ?? ''}
            onChange={e => updateForm('drop', e.target.value)}
            type='number'
            unit='m'
          />
        </div>
      </div>
    </div>
  );
};

interface IFormData {
  is_final: boolean;
  amount: number | null;
  income: number | null;
  complete_date: number;
  company: string | null;
  vessel: string | null;
  harvest_number: string | null;
  bags_quantity: number;
  tag_color: string | null;
  port_of_unload: string | null;
  crop_owner: string | null;
  growing_area: string | null;
  delivered_to: string | null;
  packhouse: string | null;
  shell_length: number | null;
  shell_length_max: number | null;
  shell_condition: string | null;
  line_length: number | null;
  direction: string | null;
  mussel_type: string | null;
  mussels: number | null;
  meat_yield: number | null;
  blues: number | null;
  marine_waste: string | null;
  backbone_ok: boolean;
  backbone_replace: boolean;
  lights_ids_in_place: boolean;
  flotation_on_farm: boolean;
  rope_bags_quantity: number | null;
  product_left_on_line: string | null;
  harvester_name: string | null;
  signature?: string | null;
  comment: string | null;
  images: any[];
  is_waste: boolean;
  stripped_reason: string | null;
  time_tracks?: ITimeRange[];
  cost?: number | null;
  inventories?: IFloatSum[];
  harvest_spats?: IHarvestSpat[];
  custom_values?: ICustomValue[];
}

const defaultForm: IFormData = {
  is_final: true,
  amount: null,
  income: null,
  complete_date: moment().toDate().getTime(),
  company: null,
  vessel: null,
  harvest_number: null,
  bags_quantity: 0,
  tag_color: null,
  port_of_unload: null,
  crop_owner: null,
  growing_area: null,
  delivered_to: null,
  packhouse: null,
  shell_length: null,
  shell_length_max: null,
  shell_condition: null,
  line_length: null,
  direction: null,
  mussel_type: null,
  mussels: null,
  meat_yield: null,
  blues: null,
  marine_waste: null,
  backbone_ok: false,
  backbone_replace: false,
  lights_ids_in_place: false,
  flotation_on_farm: false,
  rope_bags_quantity: null,
  product_left_on_line: null,
  harvester_name: null,
  signature: null,
  comment: null,
  images: [],
  is_waste: false,
  stripped_reason: null,
  time_tracks: [],
};

const HarvestSpatsView: React.FC<{
  spats: IMusselLineResource['growing_spats'];
  data: IHarvestSpat[];
  setData: (d: IHarvestSpat[]) => void;
}> = ({ spats, data, setData }) => {
  const lang = useSelector(selectLang);

  const selectHarvestSpats = (v: string[]) =>
    setData(
      v.map(
        i =>
          data.find(x => x.spat_storage_id === Number(i)) ?? {
            spat_storage_id: Number(i),
            amount: null,
            line_length: null,
          },
      ),
    );
  const updateRow = (
    id: number,
    key: 'amount' | 'line_length',
    val: string,
  ) => {
    let tmp = [...data];
    const i = tmp.findIndex(x => x.spat_storage_id === id);
    tmp[i][key] = val.length > 0 ? Number(val) : null;
    setData(tmp);
  };

  return (
    <div>
      <Dropdown
        label={translate(lang, 'Select harvested spats')}
        value={data.map(x => x.spat_storage_id.toString()) as any}
        options={
          spats?.map(x => ({
            id: x.id.toString(),
            value: x.id.toString(),
            label: x.source,
          })) ?? []
        }
        onChange={(v: any) => selectHarvestSpats(v)}
        mode='multiple'
        className='mb-17'
      />
      {data.map((x, k) => (
        <div
          className='d-flex mb-7 pb-7 align-items-center'
          key={k}
          style={{ borderBottom: '1px solid #efefef' }}
        >
          <div className='mr-7' style={{ fontWeight: 'bold' }}>
            {spats?.find(y => y.id === x.spat_storage_id)?.source ?? ''}
          </div>
          <div className='mr-7'>
            <Input
              label={translate(lang, 'Harvested Amount')}
              type='number'
              value={x.amount?.toString() ?? ''}
              onChange={e =>
                updateRow(x.spat_storage_id, 'amount', e.target.value)
              }
              unit='kg'
            />
          </div>
          <div className='ml-7'>
            <Input
              label={translate(lang, 'Harvested length')}
              value={x.line_length?.toString() ?? ''}
              onChange={e =>
                updateRow(x.spat_storage_id, 'line_length', e.target.value)
              }
              type='number'
              unit='m'
            />
          </div>
        </div>
      ))}
    </div>
  );
};

interface PartialDialogProps {
  onConfirm: (d: any) => void;
}

const ConfirmPartialDialog = ({ onConfirm }: PartialDialogProps) => {
  const lang = useSelector(selectLang);

  return (
    <div className='confirm-partial-dialog'>
      <div className='mt-32 mb-32'>
        <Title size={6} color='default' align='center' fontWeight={500}>
          {translate(lang, 'Have you harvested the entire line?')}
        </Title>
      </div>
      <div className='d-flex justify-content-between mt-16 mb-32'>
        <Button
          width={'wide'}
          size={2}
          type='fill'
          color='green'
          className='mr-16'
          onClick={() => onConfirm(false)}
        >
          {translate(lang, 'Yes, the line is now empty')}
        </Button>
        <Button
          width={'wide'}
          size={2}
          type='fill'
          color='blue'
          className='ml-16'
          onClick={() => onConfirm(true)}
        >
          {translate(lang, 'No, the crop is still there')}
        </Button>
      </div>
    </div>
  );
};

interface SCDProps {
  amount: number;
  onConfirm: () => void;
  onCancel: () => void;
}

const SubmitConfirmDialog = ({ amount, onConfirm, onCancel }: SCDProps) => {
  const lang = useSelector(selectLang);

  return (
    <div className='confirm-partial-dialog'>
      <div className='mt-32 mb-32'>
        <Paragrapgh size={6} color='default' align='left' fontWeight={500}>
          {translate(lang, '_harvest_confirm_dialog_1')}
          <br />
          {translate(lang, '_harvest_confirm_dialog_2')}
          <br />
          {translate(
            lang,
            'Please confirm that your value %s is correct',
            amount,
          )}
        </Paragrapgh>
      </div>
      <div className='d-flex justify-content-between mt-16 mb-32'>
        <Button
          width={'wide'}
          size={2}
          type='fill'
          color='green'
          className='mr-16'
          onClick={onCancel}
        >
          {translate(lang, 'Go back and correct')}
        </Button>
        <Button
          width={'wide'}
          size={2}
          type='fill'
          color='blue'
          className='ml-16'
          onClick={onConfirm}
        >
          {translate(lang, 'Confirm the value')}
        </Button>
      </div>
    </div>
  );
};

const reasonOptions = [
  {
    id: '0',
    value: '0',
    label: 'Select a reason',
  },
  {
    id: '1',
    value: 'High mortality',
    label: 'High mortality',
  },
  {
    id: '2',
    value: 'Low quality',
    label: 'Low quality',
  },
  {
    id: '3',
    value: 'Other',
    label: 'Other - please use comments',
  },
];

interface WasteProps {
  formData: IFormData;
  setFormData: (d: IFormData) => void;
  disabled?: boolean;
}

const WasteHarvest = ({ formData, setFormData, disabled }: WasteProps) => {
  const lang = useSelector(selectLang);

  return (
    <>
      <div className='mb-17'>
        <Datepicker
          defaultValue={formData.complete_date}
          label={translate(lang, 'Harvest complete date')}
          onChange={e =>
            e &&
            !disabled &&
            setFormData({ ...formData, complete_date: e.toDate().getTime() })
          }
          disabled={disabled}
        />
      </div>
      <div className='mb-17'>
        <Dropdown
          label={translate(lang, 'Strip reason')}
          options={reasonOptions}
          value={formData.stripped_reason ?? '0'}
          onChange={v =>
            !disabled &&
            setFormData({ ...formData, stripped_reason: v === '0' ? null : v })
          }
          disabled={disabled}
        />
      </div>
      <div className='mb-17'>
        <Input
          label={translate(lang, 'Comment')}
          placeholder={translate(lang, 'Comment')}
          type='textarea'
          onChange={e =>
            !disabled && setFormData({ ...formData, comment: e.target.value })
          }
          value={formData.comment ?? ''}
          name='comment'
          disabled={disabled}
        />
      </div>
      <div className='mb-17'>
        <PicturesWall
          label={translate(lang, 'Images')}
          fileList={formData.images}
          handleChangeImages={v => setFormData({ ...formData, images: v })}
        />
      </div>
    </>
  );
};

interface IAvlInventory {
  inventory_id: number;
  available: number;
}

interface IPickedItem {
  inventory_id: number;
  seeding_id: number;
  quantity: number | null;
}

interface InventoriesProps {
  farmID: number;
  seedingId: number;
  availableInventories: IAvlInventory[];
  pickedItems: IPickedItem[];
  setPickedItems: (x: any) => void;
}

const ReturnInventories = ({
  farmID,
  seedingId,
  availableInventories,
  pickedItems,
  setPickedItems,
}: InventoriesProps) => {
  const lang = useSelector(selectLang);
  const allIVTs = SelectInventoriesByFarmID(farmID);

  const updateForm = (ivt_id: number, val: string) => {
    const v = val.length <= 0 ? null : Number(val);
    let f = [...pickedItems];
    const i = f.findIndex(x => x.inventory_id === ivt_id);
    if (i < 0) {
      f.push({ inventory_id: ivt_id, seeding_id: seedingId, quantity: v });
    } else {
      f[i].quantity = v;
    }
    setPickedItems(f);
  };
  const label = (i: number) => {
    const t = allIVTs.find(x => x.id === i);
    return `${t?.subtype} - $${t?.price}`;
  };

  return availableInventories.length > 0 ? (
    <div className='mb-32 mt-17'>
      <Paragrapgh
        size={2}
        color='black'
        align='left'
        fontWeight={400}
        className='pb-12'
      >
        {translate(
          lang,
          'Please input quantity of inventories that you are returning',
        )}
      </Paragrapgh>
      {availableInventories.map(item => (
        <div className='mb-17' key={item.inventory_id}>
          <Input
            label={label(item.inventory_id)}
            placeholder={translate(lang, 'Maximum value is %s', item.available)}
            type='number'
            onChange={e => updateForm(item.inventory_id, e.target.value)}
            value={
              pickedItems
                .find(x => x.inventory_id === item.inventory_id)
                ?.quantity?.toString() ?? ''
            }
            max={item.available}
          />
        </div>
      ))}
    </div>
  ) : (
    <></>
  );
};

interface Props {
  visible: boolean;
  title?: string;
  lineData: IMusselLineResource;
  data?: any;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  overConfirm?: (data: any) => Promise<any>;
  onlyView?: boolean;
  warningMessage?: string;
  lineChangeable?: boolean;
  updateID?: number;
}

const MusselHarvestModal = ({
  visible,
  title,
  lineData,
  data,
  onCancel,
  onConfirm,
  overConfirm,
  onlyView,
  warningMessage,
  lineChangeable,
  updateID,
}: Props) => {
  const dispatch = useDispatch<any>();

  const lang = useSelector(selectLang);
  const profile = SelectProfileByFarmID(lineData.farm_id);
  const friendEmails = SelectSettingsByFarmID(lineData.farm_id)?.friendEmails;
  const farmsData = SelectMusselFarmsByFarmID(lineData.farm_id);
  const showBudget = SelectIsBudgetVisible(lineData.farm_id);
  const seedStages = SelectUserMeta(lineData.farm_id)?.seed_stages_size;
  const farmUtils = SelectUtilsByFarmID(lineData.farm_id);
  const customFields = SelectHarvestFields(lineData.farm_id);

  const port_of_unloads = farmUtils.filter(x => x.type === 'port_of_unload'),
    delivered_tos = farmUtils.filter(x => x.type === 'delivered_to'),
    packhouses = farmUtils.filter(x => x.type === 'packhouse'),
    musselTypes = farmUtils.filter(x => x.type === 'harvest_type');

  const [showPort, setShowPort] = useState(
    !port_of_unloads || port_of_unloads?.length <= 0,
  );
  const [showDel, setShowDel] = useState(
    !delivered_tos || delivered_tos?.length <= 0,
  );
  const [showPac, setShowPac] = useState(
    !packhouses || packhouses?.length <= 0,
  );
  const [currentFarm, setCurrentFarm] = useState(
    farmsData.find(x => x.id === lineData.farm_id),
  );
  const [currentLine, setCurrentLine] = useState(lineData);
  const [errorMsg, setErrorMsg] = useState<string>();
  const [errors, setErrors] = useState<any>();

  const { currentSeed, ivtFloats, growingLength, lastAssessment, lastHarvest } =
    useMemo(
      () => ({
        currentSeed: currentLine.growing_cycle?.main_seed,
        ivtFloats: currentLine.growing_cycle?.inventories_sum,
        growingLength: currentLine.growing_cycle?.current_line_length ?? 0,
        lastAssessment: currentLine.growing_cycle?.last_assessment,
        lastHarvest: currentLine.growing_cycle?.last_harvest,
      }),
      [currentLine],
    );

  const handleLineChange = (v: string) => {
    if (!currentFarm) return;
    const line = currentFarm.lines.find(x => x.id === Number(v));
    if (!line?.growing_cycle) return;
    setCurrentLine(line);
    setFormData(prv => ({
      ...prv,
      inventories: line.growing_cycle?.inventories_sum,
      is_final: line.growing_cycle?.last_harvest
        ? line.growing_cycle.last_harvest.is_final
        : prv.is_final,
    }));
  };

  const [disabled, setDisabled] = useState(false);
  const [isPartial, setIsPartial] = useState<boolean | null>(null);
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [formData, setFormData] = useState<IFormData>({
    ...defaultForm,
    harvester_name: profile?.name ?? null,
    growing_area: `${currentLine.farm_name} - ${currentLine.line_name}`,
    vessel: profile?.current_boat
      ? `${profile.current_boat.name} (${profile.current_boat.reg_number})`
      : null,
    inventories: lineData.growing_cycle?.inventories_sum,
    crop_owner:
      currentFarm && currentFarm.owners && currentFarm.owners.length > 0
        ? currentFarm.owners[0].title
        : null,
    company: profile?.company_name ?? null,
    port_of_unload:
      port_of_unloads && port_of_unloads.length > 0
        ? port_of_unloads[0].name
        : defaultForm.port_of_unload,
    delivered_to:
      delivered_tos && delivered_tos.length > 0
        ? delivered_tos[0].name
        : defaultForm.delivered_to,
    packhouse:
      packhouses && packhouses.length > 0
        ? packhouses[0].name
        : defaultForm.packhouse,
    is_final: lineData.growing_cycle?.last_harvest
      ? !!lineData.growing_cycle.last_harvest.is_final
      : defaultForm.is_final,
  });
  const [reqPdf, setReqPdf] = useState(false);
  const signatureCanvas = useRef<SignatureCanvas | null>();
  const [sigCanLoaded, setSigCanLoaded] = useState(false);
  const [emails, setEmails] = useState(friendEmails?.join(',') ?? '');
  const [advancedLine, setAdvancedLine] = useState<IMusselLineDetail>();
  const [harvestSpats, setHarvestSpats] = useState<IHarvestSpat[]>([]);
  const [trackMode, setTrackMode] = useState(false);
  const [customValues, setCustomValues] = useState(
    customFields.map(x => {
      const d =
        data && data.custom_values
          ? data.custom_values.find((y: ICustomValue) => y.field_id === x.id)
          : null;
      return {
        input_type: x.input_type,
        field_label: x.name,
        options: x.options
          ?.split(',')
          .map(t => ({ id: t, label: t, value: t })),
        field_id: x.id,
        field_val: d?.field_val ?? '',
        data_type: x.data_type,
        unit: x.unit,
      };
    }),
  );
  const [reseedFarm, setReseedFarm] = useState(currentFarm);
  const [reseedForm, setReseedForm] = useState<IReseed>();
  const [loading, setLoading] = useState(false);

  const updateHarvestSpats = (d: IHarvestSpat[]) => {
    setHarvestSpats(d);
    const as = d.reduce((p, c) => p + (c.amount ?? 0), 0);
    updateForm('amount', as);
    const ls = d.reduce((p, c) => p + (c.line_length ?? 0), 0);
    updateForm('line_length', ls);
  };
  const toggleTrackMode = async (c: boolean) => {
    if (c && (!advancedLine || advancedLine.id !== currentLine.id)) {
      setLoading(true);
      const response = await sendSingleRequest(
        { with_harvest_spats: true, track_harvest_id: updateID },
        'GET',
        `api/farm/line/lines/${currentLine.id}`,
        true,
      );
      setLoading(false);
      if (response.status) {
        setAdvancedLine(response.data);
      }
    }
    setTrackMode(c);
  };
  const updateForm = (key: keyof IFormData, value: any) => {
    if (
      [
        'amount',
        'income',
        'bags_quantity',
        'shell_length',
        'shell_length_max',
        'mussels',
        'meat_yield',
        'blues',
        'rope_bags_quantity',
      ].includes(key)
    ) {
      value = value.length <= 0 ? null : Number(value);
      if (key === 'amount') {
        const bags = Math.floor((999 + value) / 1000);
        setFormData(prv => ({ ...prv, amount: value, bags_quantity: bags }));
      } else {
        setFormData(prv => ({ ...prv, [key]: value }));
      }
    } else if (key === 'is_final' && !value) {
      setFormData(prv => ({
        ...prv,
        is_final: false,
        is_waste: false,
        stripped_reason: null,
      }));
    } else if (key === 'is_waste') {
      setFormData(prv => ({
        ...prv,
        is_waste: !!value,
        stripped_reason: null,
      }));
    } else {
      if (typeof value === 'string' && value.length <= 0) {
        value = null;
      }
      setFormData(prv => ({ ...prv, [key]: value }));
    }
    setErrors((prv: any) => ({ ...(prv ?? {}), [key]: null }));
  };
  const validForm = () => {
    if (!updateID && !currentLine.growing_cycle) {
      setErrorMsg('_line_empty');
      return null;
    }
    if (!!formData.is_waste && formData.stripped_reason === null) {
      setErrorMsg('Please select a stripped reason');
      setErrors((prv: any) => ({
        ...(prv ?? {}),
        stripped_reason: translate(lang, 'Please select a stripped reason'),
      }));
      return null;
    }
    if (!updateID && !currentSeed) {
      setErrorMsg('_line_empty');
      return null;
    }
    const line = farmsData
      .find(x => x.id === currentFarm?.id)
      ?.lines.find(y => y.id === currentLine.id);
    if (
      updateID &&
      !data.is_full !== isPartial &&
      line?.last_harvest?.id !== updateID
    ) {
      setErrorMsg('_impossible_harvest_update');
      return null;
    }
    if (
      !updateID &&
      isPartial === true &&
      formData.line_length &&
      growingLength < formData.line_length
    ) {
      const es = !formData.line_length
        ? translate(lang, 'Please input Harvested length')
        : translate(
            lang,
            'Maximum available harvest length is %s',
            growingLength,
          );
      setErrorMsg(es);
      setErrors((prv: any) => ({ ...(prv ?? {}), line_length: es }));
      return null;
    }
    if (
      !updateID &&
      isPartial === false &&
      formData.line_length &&
      formData.line_length !== growingLength &&
      !window.confirm(
        `${growingLength}m are left but you are going to harvest ${formData.line_length}m as a full harvest. Are you sure?`,
      )
    ) {
      return null;
    }
    if (
      !formData.is_waste &&
      (formData.amount === null || formData.amount <= 0)
    ) {
      const es =
        formData.amount === null
          ? translate(lang, 'Please enter harvested amount')
          : translate(lang, '_invalid_harvest_amount_length');
      setErrorMsg(es);
      setErrors((prv: any) => ({ ...(prv ?? {}), amount: es }));
      return null;
    }
    if (
      formData.shell_length_max !== null &&
      (formData.shell_length === null ||
        formData.shell_length > formData.shell_length_max)
    ) {
      const es = translate(lang, 'Invalid shell length range');
      setErrorMsg(es);
      setErrors((prv: any) => ({ ...(prv ?? {}), shell_length: es }));
      return null;
    }
    if (emails && emails.split(',').some(x => !isEmailFormat(x))) {
      setErrorMsg('_invalid_emails');
      return null;
    }
    if (reseedForm) {
      if (!formData.shell_length) {
        setErrorMsg('Please input shell length');
        return null;
      }
      if (!reseedFarm) {
        setErrorMsg('Please select immediate seed farm');
        return null;
      }
      if (reseedForm.lines.some(x => !x.line_length)) {
        setErrorMsg('Please input line length for all immediate seed lines');
        return null;
      }
    }
    let res = {
      ...formData,
      seeding_id: data?.seeding_id ?? currentSeed?.id,
      season_name: data?.season_name ?? currentSeed?.season_name,
      line_id: currentLine.id,
      complete_date: toggleSecondMillisecond(formData.complete_date),
      is_full: !isPartial,
      reseed: reseedForm,
      require_pdf: reqPdf,
      request_emails: emails.split(','),
    };
    if (trackMode && harvestSpats.length > 0) {
      if (harvestSpats.some(x => !x.amount || !x.line_length)) {
        setErrorMsg('Harvested Original spats are not valid');
        setErrors((prv: any) => ({
          ...(prv ?? {}),
          amount: translate(lang, '_invalid_harvest_amount_length'),
        }));
        return null;
      }
      res.harvest_spats = harvestSpats;
    }
    if (!res.income) res.income = 0;
    if (!updateID && signatureCanvas.current?.toDataURL()) {
      res.signature = signatureCanvas.current?.toDataURL() ?? null;
    } else {
      delete res.signature;
    }
    if (res.inventories) {
      let tmp: any = [];
      for (let x of res.inventories) {
        if (!x.quantity || x.quantity < 0) continue;
        const t = availableInventories.find(
          y => x.inventory_id === y.inventory_id,
        );
        if (!t) {
          setErrorMsg('_unknown_error');
          return null;
        }
        if (x.quantity > t.available) {
          setErrorMsg('_float_no_enough');
          return null;
        }
        tmp.push(x);
      }
      res.inventories = tmp;
    }
    if (res.time_tracks && res.time_tracks.length > 0) {
      const tt = toSecTimeTracks(res.time_tracks);
      if (!tt) {
        setErrorMsg('_invalid_time_range');
        return null;
      }
      res.time_tracks = tt;
    }
    if (res.is_waste) {
      res.amount = res.income = 0;
    }
    const cvs = customValues
      .filter(x => !!x.field_val)
      .map(({ field_id, field_val }) => ({ field_id, field_val }));
    if (cvs.length > 0) {
      res.custom_values = cvs;
    } else {
      res.custom_values = undefined;
    }
    setErrors(undefined);
    return res;
  };
  const handleConfirmClick = async () => {
    const form = validForm();
    if (!form) return;

    setDisabled(true);
    if (overConfirm) {
      const res = await overConfirm(form);
      if (!res.status) {
        setErrorMsg(res.data?.message ?? 'Request failed');
        if (res.data.errors) setErrors(res.data.errors);
        setDisabled(false);
      }
      return;
    }
    const res = !updateID
      ? await sendSingleRequest(
          form,
          'POST',
          'api/farm/line/harvest-complete',
          true,
        )
      : await sendSingleRequest(
          form,
          'POST',
          `api/farm/line/harvest-update/${updateID}`,
          true,
        );
    if (res.status) {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, res.data?.message ?? 'Success'),
        }),
      );
      dispatch(loadFarmsData());
      if (form.inventories) {
        dispatch(loadInventories());
      }
      onConfirm(res.data);
    } else {
      setErrorMsg(res.data?.message ?? 'Request failed');
      if (res.data.errors) setErrors(res.data.errors);
      setDisabled(false);
    }
  };
  const updateCustomValue = (fieldID: number, val: string) => {
    let tmp = [...customValues];
    const i = tmp.findIndex(x => x.field_id === fieldID);
    if (i >= 0) {
      tmp[i].field_val = val;
    }
    setCustomValues(tmp);
  };

  const farmOptions = farmsData.map(x => ({
    id: x.id.toString(),
    value: x.id.toString(),
    label: `${x.name} - ${x.farm_number}`,
  }));
  const lineOptions = currentFarm?.lines
    .filter(x => !!x.growing_cycle)
    .map(x => ({
      id: x.id.toString(),
      value: x.id.toString(),
      label: x.line_name,
    }));

  const { plannedDaysDiff, currentDaysDiff } = useMemo(() => {
    if (!currentSeed) {
      return { plannedDaysDiff: 0, currentDaysDiff: 0 };
    }
    const t = moment().format('YYYY-MM-DD');
    const s = moment(currentSeed.planned_date_seed * 1000).format('YYYY-MM-DD');

    let h: any = null;
    if (!seedStages || formData.shell_length === null) {
      h = moment(
        (lastAssessment?.planned_date_harvest ??
          currentSeed.planned_date_harvest) * 1000,
      ).format('YYYY-MM-DD');
    } else {
      let i = 0;
      for (
        i = 0;
        i < seedStages.length &&
        seedStages[i].size <= Number(formData.shell_length);
        i++
      );
      if (i > 0) {
        h = moment(s).add(seedStages[i - 1].time, 'months');
      } else {
        h = moment(
          (lastAssessment?.planned_date_harvest ??
            currentSeed.planned_date_harvest) * 1000,
        ).format('YYYY-MM-DD');
      }
    }
    return {
      plannedDaysDiff: moment(t).diff(moment(h), 'd'),
      currentDaysDiff: moment(t).diff(moment(s), 'd'),
    };
  }, [currentSeed, seedStages, formData.shell_length]);

  useEffect(() => {
    if (data) {
      let tmp = { ...data };
      if (showBudget && data.expense_items && data.expense_items.length > 0) {
        tmp.cost = data.expense_items.reduce(
          (p: number, c: any) => p + c.amount,
          0,
        );
      }
      if (tmp.complete_date)
        tmp.complete_date = toggleSecondMillisecond(tmp.complete_date);
      if (tmp.images) {
        tmp.images = tmp.images.map((x: any) =>
          typeof x === 'object' && x !== null ? x : parseImageUrlToFileItem(x),
        );
      }
      if (tmp.is_full !== undefined && tmp.is_full !== null) {
        setIsPartial(!tmp.is_full);
      }
      if (data.time_tracks) {
        tmp.time_tracks = data.time_tracks.map((x: any) => ({
          start_time: toggleSecondMillisecond(x.start_time),
          finish_time: toggleSecondMillisecond(x.finish_time),
        }));
      }
      setFormData(prv => ({ ...prv, ...tmp }));

      if (data.harvest_spats && data.harvest_spats.length > 0) {
        setHarvestSpats(
          data.harvest_spats.map((x: any) => ({
            ...x,
            spat_storage_id: x.spat_storage_id ?? x.spat_storage.id,
          })),
        );
        toggleTrackMode(true);
      }
      if (data.reseed && data.reseed.lines) {
        const tmpFarm = farmsData.find(x =>
          x.lines.some(y => y.id === data.reseed.lines[0].line_id),
        );
        setReseedFarm(tmpFarm);
        setReseedForm(data.reseed);
      }
    }
  }, [data, showBudget]);

  useEffect(() => {
    if (sigCanLoaded && data && data.signature) {
      signatureCanvas.current?.fromDataURL(data.signature);
    }
  }, [data, sigCanLoaded, signatureCanvas.current]);

  useEffect(() => {
    setFormData(prv => ({
      ...prv,
      line_length: prv.line_length ? prv.line_length : growingLength,
    }));
  }, [growingLength]);

  const availableInventories = useMemo(() => {
    let res: IAvlInventory[] = [];
    if (ivtFloats) {
      for (let x of ivtFloats) {
        res.push({
          inventory_id: x.inventory_id,
          available: x.quantity,
        });
      }
    }
    if (data?.inventories) {
      for (let x of data.inventories) {
        if (res.some(t => t.inventory_id === x.inventory_id)) continue;
        res.push({
          inventory_id: x.inventory_id,
          available: 0,
        });
      }
    }
    return res;
  }, [data?.inventories, ivtFloats]);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
      width={650}
    >
      <div className='wrap'>
        {loading && (
          <div className='loader'>
            <Spinner />
          </div>
        )}
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, title ?? 'Mussel Harvest Form')}
          </Subtitle>
        </div>
        <div className='harvest-modal'>
          {lineChangeable && (
            <div className='mb-17 d-flex'>
              <div className='w-100 mr-7'>
                <Dropdown
                  label={translate(lang, 'Select farm')}
                  options={farmOptions}
                  value={currentFarm?.id.toString()}
                  onChange={v =>
                    setCurrentFarm(farmsData.find(x => x.id === Number(v)))
                  }
                />
              </div>
              <div className='w-100 ml-7'>
                <Dropdown
                  label={translate(lang, 'Select line')}
                  options={lineOptions ?? []}
                  value={
                    !!lineOptions &&
                    lineOptions?.some(x => Number(x.id) === currentLine.id)
                      ? currentLine.id.toString()
                      : currentLine.line_name
                  }
                  onChange={handleLineChange}
                />
              </div>
            </div>
          )}
          {!!warningMessage && (
            <div className='header-alert'>{warningMessage}</div>
          )}
          {!!errorMsg && (
            <Feedback
              message={translate(lang, errorMsg)}
              type={'error'}
              theme='light'
              position={10}
              isGlobal
              onClose={() => setErrorMsg(undefined)}
            />
          )}
          {isPartial === null ? (
            <ConfirmPartialDialog onConfirm={x => setIsPartial(x)} />
          ) : visibleConfirm ? (
            <SubmitConfirmDialog
              amount={Number(formData.amount)}
              onConfirm={handleConfirmClick}
              onCancel={() => setVisibleConfirm(false)}
            />
          ) : (
            <div>
              {!!currentSeed && (
                <>
                  <Paragrapgh
                    size={2}
                    color='black'
                    align='left'
                    fontWeight={400}
                    className='pb-25'
                  >
                    {componentTranslate(
                      lang,
                      '_harvest_intro',
                      <span key={1} className='pr-6 pl-3 font-weight-500'>
                        {defaultDateFormat(currentSeed.planned_date_seed)}
                      </span>,
                      <span key={2} className='pl-4 font-weight-500'>
                        {defaultDateFormat(
                          lastAssessment?.planned_date_harvest ??
                            currentSeed.planned_date_harvest,
                        )}
                      </span>,
                    )}
                    <br />
                    <span style={{ fontWeight: 700, color: 'black' }}>
                      {translate(
                        lang,
                        'Your harvest is %s days %s schedule.',
                        Math.abs(plannedDaysDiff),
                        plannedDaysDiff <= 0 ? ' ahead' : ' behind',
                      )}
                      {plannedDaysDiff <= 0 && ' Well done!'}
                      <br />
                      {translate(
                        lang,
                        'Your crop grew for %s days.',
                        currentDaysDiff,
                      )}
                    </span>
                  </Paragrapgh>
                  <ReturnInventories
                    farmID={lineData.farm_id}
                    seedingId={currentSeed.id}
                    availableInventories={availableInventories}
                    pickedItems={formData.inventories ?? []}
                    setPickedItems={d =>
                      setFormData(prv => ({ ...prv, inventories: d }))
                    }
                  />
                </>
              )}
              <div className='pl-17 mb-32 mt-7 pr-17'>
                <Subtitle
                  size={4}
                  fontWeight={700}
                  align='left'
                  color='black-3'
                >
                  {translate(lang, 'Have you harvested the entire line?')}
                </Subtitle>
                <Radio.Group
                  onChange={e => setIsPartial(e.target.value === 'P')}
                  value={isPartial ? 'P' : 'F'}
                  disabled={onlyView}
                >
                  <RadioButton
                    label={translate(lang, 'Yes, the line is now empty')}
                    value='F'
                    className='mt-7'
                  />
                  <RadioButton
                    label={translate(lang, 'No, the crop is still there')}
                    value='P'
                    className='mt-7'
                  />
                </Radio.Group>
              </div>
              <div className='mb-17'>
                {!!lastHarvest && lastHarvest.is_final == formData.is_final && (
                  <div>
                    <Title
                      size={6}
                      color='black'
                      align='center'
                      fontWeight={500}
                    >
                      {translate(
                        lang,
                        lastHarvest.is_final
                          ? `This is final harvest continuation`
                          : `This is intermediate harvest continuation`,
                      )}
                    </Title>
                  </div>
                )}
                <div className='pl-17 mb-17 mt-7 pr-17'>
                  <Radio.Group
                    onChange={e =>
                      updateForm('is_final', e.target.value === 'FINAL')
                    }
                    value={!formData.is_final ? 'MIDDLE' : 'FINAL'}
                    disabled={onlyView}
                  >
                    <RadioButton
                      label={translate(lang, '_radio_final')}
                      value='FINAL'
                      className='mt-7'
                    />
                    <RadioButton
                      label={translate(lang, '_radio_intermediate')}
                      value='MIDDLE'
                      className='mt-7'
                    />
                  </Radio.Group>
                </div>
                {!updateID && !formData.is_final && (
                  <div className='pl-17'>
                    <div className='mb-17'>
                      <CheckboxButton
                        label={'Put spat new line'}
                        onChange={e =>
                          setReseedForm(
                            e.target.checked ? { lines: [] } : undefined,
                          )
                        }
                        checked={!!reseedForm}
                        disabled={onlyView || disabled}
                      />
                    </div>
                    {reseedForm && (
                      <ReseedForm
                        farmsData={farmsData}
                        farm={reseedFarm}
                        setFarm={setReseedFarm}
                        form={reseedForm}
                        setForm={setReseedForm}
                      />
                    )}
                  </div>
                )}
              </div>
              {isPartial === false && !!formData.is_final && (
                <div className='mb-17'>
                  <CheckboxButton
                    label={translate(lang, 'Stripped To Waste')}
                    onChange={e => updateForm('is_waste', e.target.checked)}
                    checked={formData.is_waste}
                    disabled={onlyView || !!updateID}
                  />
                </div>
              )}
              {formData.is_waste ? (
                <WasteHarvest
                  formData={formData}
                  setFormData={setFormData}
                  disabled={onlyView}
                />
              ) : (
                <>
                  <div className='mt-32 mb-17'>
                    <div className={errors?.amount ? 'invalid-form' : ''}>
                      {!!profile?.account?.track_original_spat && (
                        <CheckboxButton
                          label={translate(lang, 'Track Original Spats')}
                          className='mb-12'
                          onChange={e => toggleTrackMode(e.target.checked)}
                          checked={trackMode}
                        />
                      )}
                      {!!trackMode && (
                        <HarvestSpatsView
                          spats={advancedLine?.growing_spats}
                          data={harvestSpats}
                          setData={updateHarvestSpats}
                        />
                      )}
                      <Input
                        type='number'
                        onChange={e => updateForm('amount', e.target.value)}
                        value={formData.amount?.toString() ?? ''}
                        label={translate(lang, 'Harvest amount')}
                        unit='kg'
                        disabled={onlyView}
                      />
                      {!!errors?.amount && (
                        <div className='invalid-feedback'>{errors?.amount}</div>
                      )}
                    </div>
                  </div>
                  {!!showBudget && (
                    <div className='mb-17'>
                      <Input
                        type='number'
                        onChange={e => updateForm('income', e.target.value)}
                        value={formData.income?.toString() ?? ''}
                        unit={<DollarIcon />}
                        label={translate(lang, 'Harvest income')}
                        disabled={onlyView}
                      />
                    </div>
                  )}
                  <div
                    className={
                      errors?.complete_date ? 'invalid-form pb-24' : 'pb-24'
                    }
                  >
                    <Datepicker
                      className='mb-17'
                      defaultValue={formData.complete_date}
                      label={translate(lang, 'Harvest complete date')}
                      onChange={e =>
                        e && updateForm('complete_date', e.toDate().getTime())
                      }
                      disabled={onlyView}
                    />
                    {!!errors?.complete_date && (
                      <div className='invalid-feedback'>
                        {errors?.complete_date}
                      </div>
                    )}
                  </div>
                  {formData.is_final ? (
                    <>
                      <div className='pb-10'>
                        <Input
                          type='text'
                          onChange={e => updateForm('company', e.target.value)}
                          value={formData.company ?? ''}
                          label={translate(lang, 'Company')}
                          disabled={onlyView}
                        />
                      </div>
                      <div className='pb-10'>
                        <Input
                          type='text'
                          onChange={e => updateForm('vessel', e.target.value)}
                          value={formData.vessel ?? ''}
                          label={translate(lang, 'Vessel')}
                          disabled={onlyView}
                        />
                      </div>
                      <div className='pb-10'>
                        <Input
                          type='text'
                          onChange={e =>
                            updateForm('harvest_number', e.target.value)
                          }
                          value={formData.harvest_number ?? ''}
                          label={`${translate(lang, 'Harvest number')}${
                            currentLine.last_harvest?.harvest_number
                              ? ` (${translate(
                                  lang,
                                  'last value is %s',
                                  currentLine.last_harvest.harvest_number,
                                )})`
                              : ''
                          }`}
                          disabled={onlyView}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='pb-10 d-flex justify-content-between'>
                        <div className='w-100 mr-7'>
                          <Input
                            label={translate(lang, 'Shell length from')}
                            type='number'
                            onChange={e =>
                              updateForm('shell_length', e.target.value)
                            }
                            value={formData.shell_length?.toString() ?? ''}
                            disabled={onlyView}
                          />
                        </div>
                        <div className='w-100 ml-7'>
                          <Input
                            label={translate(lang, 'Shell length to')}
                            type='number'
                            onChange={e =>
                              updateForm('shell_length_max', e.target.value)
                            }
                            value={formData.shell_length_max?.toString() ?? ''}
                            disabled={onlyView}
                          />
                        </div>
                      </div>
                      <div className='pb-10'>
                        <Input
                          label={translate(lang, 'Shell condition')}
                          type='text'
                          onChange={e =>
                            updateForm('shell_condition', e.target.value)
                          }
                          value={formData.shell_condition ?? ''}
                          disabled={onlyView}
                        />
                      </div>
                    </>
                  )}
                  <div
                    className={
                      errors?.line_length ? 'invalid-form pb-10' : 'pb-10'
                    }
                  >
                    <Input
                      label={translate(lang, 'Harvested length')}
                      placeholder={
                        !updateID
                          ? translate(lang, '%s available', `${growingLength}m`)
                          : ''
                      }
                      type='number'
                      onChange={e => updateForm('line_length', e.target.value)}
                      value={formData.line_length?.toString() ?? ''}
                      disabled={onlyView}
                    />
                    {!!errors?.line_length && (
                      <div className='invalid-feedback'>
                        {errors?.line_length}
                      </div>
                    )}
                  </div>
                  <div className='mb-17'>
                    <DirectionForm
                      value={formData.direction}
                      onChange={v => updateForm('direction', v)}
                    />
                  </div>
                  <div className='pb-10'>
                    <Input
                      label={translate(lang, 'Number of bags')}
                      type='number'
                      onChange={e =>
                        updateForm('bags_quantity', e.target.value)
                      }
                      value={formData.bags_quantity?.toString() ?? ''}
                      disabled={onlyView}
                    />
                  </div>
                  <div className='pb-10'>
                    <Input
                      label={translate(lang, 'Tag color')}
                      type='text'
                      onChange={e => updateForm('tag_color', e.target.value)}
                      value={formData.tag_color ?? ''}
                      disabled={onlyView}
                    />
                  </div>
                  <div className='pb-10'>
                    <Dropdown
                      label={translate(lang, 'Port of unload')}
                      placeholder={translate(lang, 'Select port of unload')}
                      options={[
                        ...(port_of_unloads?.map(x => ({
                          id: x.id.toString(),
                          value: x.name,
                          label: x.name,
                        })) ?? []),
                        { id: '', value: '', label: translate(lang, 'Other') },
                      ]}
                      value={
                        showPort ? '' : formData.port_of_unload ?? undefined
                      }
                      onChange={v => {
                        updateForm('port_of_unload', v);
                        setShowPort(v.length <= 0);
                      }}
                    />
                    {showPort && (
                      <Input
                        label=''
                        placeholder={translate(lang, 'Port of unload')}
                        type='text'
                        className='mt-7'
                        onChange={e =>
                          updateForm('port_of_unload', e.target.value)
                        }
                        value={formData.port_of_unload ?? ''}
                        disabled={onlyView}
                      />
                    )}
                  </div>
                  <div className='pb-10'>
                    <Input
                      label={translate(lang, 'Crop owner')}
                      type='text'
                      onChange={e => updateForm('crop_owner', e.target.value)}
                      value={formData.crop_owner ?? ''}
                      disabled={onlyView}
                    />
                  </div>
                  <div className='pb-10'>
                    <Input
                      label={translate(lang, 'Growing area')}
                      type='text'
                      onChange={e => updateForm('growing_area', e.target.value)}
                      value={formData.growing_area ?? ''}
                      disabled={onlyView}
                    />
                  </div>
                  <div className='pb-10'>
                    <Dropdown
                      label={translate(lang, 'Delivered to')}
                      placeholder={translate(lang, 'Select delivered to')}
                      options={[
                        ...(delivered_tos?.map(x => ({
                          id: x.id.toString(),
                          value: x.name,
                          label: x.name,
                        })) ?? []),
                        { id: '', value: '', label: translate(lang, 'Other') },
                      ]}
                      value={showDel ? '' : formData.delivered_to ?? undefined}
                      onChange={v => {
                        updateForm('delivered_to', v);
                        setShowDel(v.length <= 0);
                      }}
                    />
                    {showDel && (
                      <Input
                        label=''
                        placeholder={translate(lang, 'Delivered to')}
                        type='text'
                        className='mt-7'
                        onChange={e =>
                          updateForm('delivered_to', e.target.value)
                        }
                        value={formData.delivered_to ?? ''}
                        disabled={onlyView}
                      />
                    )}
                  </div>
                  <div className='pb-10'>
                    <Dropdown
                      label={translate(lang, 'Packhouse')}
                      placeholder={translate(lang, 'Select packhouse')}
                      options={[
                        ...(packhouses?.map(x => ({
                          id: x.id.toString(),
                          value: x.name,
                          label: x.name,
                        })) ?? []),
                        { id: '', value: '', label: translate(lang, 'Other') },
                      ]}
                      value={showPac ? '' : formData.packhouse ?? undefined}
                      onChange={v => {
                        updateForm('packhouse', v);
                        setShowPac(v.length <= 0);
                      }}
                    />
                    {showPac && (
                      <Input
                        label=''
                        placeholder={translate(lang, 'Packhouse')}
                        type='text'
                        className='mt-7'
                        onChange={e => updateForm('packhouse', e.target.value)}
                        value={formData.packhouse ?? ''}
                        disabled={onlyView}
                      />
                    )}
                  </div>
                  {!!formData.time_tracks && (
                    <div className='pb-7'>
                      <TimeTrackInput
                        data={formData.time_tracks}
                        onChange={t => updateForm('time_tracks', t)}
                        disabled={onlyView}
                      />
                    </div>
                  )}
                  {!!formData.is_final && (
                    <>
                      <div className='pb-10 d-flex justify-content-between'>
                        <div className='mr-7 w-100'>
                          <Input
                            label={translate(lang, 'Shell length from')}
                            type='number'
                            onChange={e =>
                              updateForm('shell_length', e.target.value)
                            }
                            value={formData.shell_length?.toString() ?? ''}
                            disabled={onlyView}
                          />
                        </div>
                        <div className='ml-7 w-100'>
                          <Input
                            label={translate(lang, 'Shell length to')}
                            type='number'
                            onChange={e =>
                              updateForm('shell_length_max', e.target.value)
                            }
                            value={formData.shell_length_max?.toString() ?? ''}
                            disabled={onlyView}
                          />
                        </div>
                      </div>
                      <div className='pb-10'>
                        <Input
                          label={translate(lang, 'Shell condition')}
                          type='text'
                          onChange={e =>
                            updateForm('shell_condition', e.target.value)
                          }
                          value={formData.shell_condition ?? ''}
                          disabled={onlyView}
                        />
                      </div>
                    </>
                  )}
                  <div className='pb-10'>
                    <Dropdown
                      label={translate(lang, 'Mussels type')}
                      options={
                        musselTypes?.map(x => ({
                          id: x.name,
                          label: x.name,
                          value: x.name,
                        })) ?? []
                      }
                      value={formData.mussel_type ?? undefined}
                      onChange={v => updateForm('mussel_type', v)}
                      disabled={onlyView}
                    />
                  </div>
                  <div className='pb-10'>
                    <Input
                      label={translate(lang, 'Performance')}
                      type='number'
                      onChange={e => updateForm('mussels', e.target.value)}
                      value={formData.mussels?.toString() ?? ''}
                      unit='mussels per kg'
                      disabled={onlyView}
                    />
                  </div>
                  <div className='pb-10'>
                    <Input
                      label={translate(lang, 'Meat yield')}
                      type='number'
                      onChange={e => updateForm('meat_yield', e.target.value)}
                      value={formData.meat_yield?.toString() ?? ''}
                      disabled={onlyView}
                      unit='%'
                    />
                  </div>
                  <div className='pb-10'>
                    <Input
                      label={translate(lang, '_blues_oysters')}
                      type='number'
                      onChange={e => updateForm('blues', e.target.value)}
                      value={formData.blues?.toString() ?? ''}
                      unit='%'
                      disabled={onlyView}
                    />
                  </div>
                  <div className='pb-10'>
                    <Input
                      label={translate(lang, '_marine_waste')}
                      type='number'
                      onChange={e => updateForm('marine_waste', e.target.value)}
                      value={formData.marine_waste ?? ''}
                      disabled={onlyView}
                      unit='kg'
                    />
                  </div>
                  <div className='pb-10'>
                    <CheckboxButton
                      label={translate(lang, 'Backbone OK')}
                      onChange={e =>
                        updateForm('backbone_ok', e.target.checked)
                      }
                      checked={formData.backbone_ok}
                      disabled={onlyView}
                    />
                  </div>
                  <div className='pb-10'>
                    <CheckboxButton
                      label={translate(lang, 'Backbone Replace')}
                      onChange={e =>
                        updateForm('backbone_replace', e.target.checked)
                      }
                      checked={formData.backbone_replace}
                      disabled={onlyView}
                    />
                  </div>
                  <div className='pb-10'>
                    <CheckboxButton
                      label={translate(lang, 'Lights and IDs in place')}
                      onChange={e =>
                        updateForm('lights_ids_in_place', e.target.checked)
                      }
                      checked={formData.lights_ids_in_place}
                      disabled={onlyView}
                    />
                  </div>
                  <div className='pb-10'>
                    <CheckboxButton
                      label={translate(lang, 'Flotation on farm')}
                      onChange={e =>
                        updateForm('flotation_on_farm', e.target.checked)
                      }
                      checked={formData.flotation_on_farm}
                      disabled={onlyView}
                    />
                  </div>
                  <div className='pb-10'>
                    <Input
                      label={translate(lang, 'Number of rope bags')}
                      type='number'
                      onChange={e =>
                        updateForm('rope_bags_quantity', e.target.value)
                      }
                      value={formData.rope_bags_quantity?.toString() ?? ''}
                      disabled={onlyView}
                    />
                  </div>
                  {!!isPartial && (
                    <div className='pb-10'>
                      <Input
                        label={translate(lang, 'Product left on line')}
                        type='text'
                        onChange={e =>
                          updateForm('product_left_on_line', e.target.value)
                        }
                        value={formData.product_left_on_line ?? ''}
                        disabled={onlyView}
                      />
                    </div>
                  )}
                  <div className='pb-10'>
                    <Input
                      label={translate(lang, "User's name")}
                      type='text'
                      onChange={e =>
                        updateForm('harvester_name', e.target.value)
                      }
                      value={formData.harvester_name ?? ''}
                      disabled={onlyView}
                    />
                  </div>
                  {!!showBudget && (
                    <div className='pb-10'>
                      <Input
                        label={translate(lang, 'Cost')}
                        type='number'
                        onChange={e => updateForm('cost', e.target.value)}
                        value={formData.cost?.toString() ?? ''}
                        unit='$'
                        disabled={onlyView}
                      />
                    </div>
                  )}
                  {customValues.map(x => (
                    <div key={x.field_id} className='mb-17'>
                      {x.input_type === 'select' ? (
                        <>
                          <Dropdown
                            label={x.field_label}
                            options={x.options ?? []}
                            value={x.field_val}
                            onChange={v => updateCustomValue(x.field_id, v)}
                            disabled={onlyView}
                          />
                          {(!x.options || x.options.length <= 0) && (
                            <Link to={'/custom-fields'}>
                              <span style={{ color: '#008ed1' }}>
                                Set up field
                              </span>
                            </Link>
                          )}
                        </>
                      ) : (
                        <Input
                          type={x.data_type === 'number' ? 'number' : 'text'}
                          label={x.field_label}
                          value={x.field_val ?? ''}
                          onChange={e =>
                            updateCustomValue(x.field_id, e.target.value)
                          }
                          unit={x.unit}
                          disabled={onlyView}
                        />
                      )}
                    </div>
                  ))}
                  <div className='pb-10'>
                    <Input
                      label={translate(lang, 'Comment')}
                      type='textarea'
                      onChange={e => updateForm('comment', e.target.value)}
                      value={formData.comment ?? ''}
                      disabled={onlyView}
                    />
                  </div>
                  <div className='pb-10'>
                    <PicturesWall
                      label={translate(lang, 'Images')}
                      fileList={formData.images}
                      handleChangeImages={v => updateForm('images', v)}
                      disabled={onlyView}
                    />
                  </div>
                  <div className='pb-20'>
                    <p className='mb-4 d-block paragrapgh paragrapgh--2 paragrapgh--400 paragrapgh--black-2 paragrapgh--default'>
                      {translate(lang, 'Signature')}
                    </p>
                    <div
                      style={{
                        border: '1px solid #EFEFEF',
                        borderRadius: '4px',
                        height: '200px',
                      }}
                    >
                      <SignatureCanvas
                        ref={r => {
                          signatureCanvas.current = r;
                          setSigCanLoaded(true);
                        }}
                        penColor='green'
                        canvasProps={{ width: 550, height: 200 }}
                      />
                    </div>
                  </div>
                  {!updateID && (
                    <div className='mb-27'>
                      <CheckboxButton
                        label={translate(
                          lang,
                          'Send me PDF of Harvest Declaration Form',
                        )}
                        checked={reqPdf}
                        onChange={e => setReqPdf(e.target.checked)}
                        disabled={onlyView}
                      />
                      {reqPdf && (
                        <div className='mt-17'>
                          <Input
                            label={translate(lang, 'Email list')}
                            placeholder={translate(
                              lang,
                              'Please enter emails separate by comma',
                            )}
                            type='text'
                            value={emails}
                            onChange={e => setEmails(e.target.value)}
                            disabled={onlyView}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {isPartial !== null && !visibleConfirm && (
        <div className='modal-button d-flex justify-content-end align-items-center'>
          <Button
            width={'small'}
            size={2}
            type='fill'
            color='green'
            className='rsp-btn ml-16'
            onClick={handleConfirmClick}
            disabled={disabled || onlyView}
          >
            {translate(lang, 'Confirm')}
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default MusselHarvestModal;
