import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Modal, Table } from 'antd';
import {
  checkRolePermission,
  formatNumber,
  numberToMoneyStr,
} from '../../entities/util-functions';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { ModalComponent, Title } from '../shared';
import ReactImageGallery from 'react-image-gallery';
import GrowerDetailModal from '../farm-modals/GrowerDetailModal';
import { EditFilled } from '@ant-design/icons';
import HarvestCompleteModal from '../farm-modals/HarvestCompleteModal';
import HarvestSpatsModal from '../farm-modals/HarvestSpatsModal';
import GrowerHarvestModal from '../farm-modals/GrowerHarvestModal';
import { TBusinessType } from '../../entities/general.entities';
import {
  IHarvestResource,
  IHarvestSpat,
  IMusselHarvest,
  IOysterHarvest,
  ISeaweedHarvest,
} from '../../entities/growing.entities';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import {
  SelectAccountSetting,
  SelectIsGrower,
  SelectProfileRole,
} from '../../store/extra/extra.selector';
import { directionChar } from '../../lib/form.helpers';

const displayHarvestTime = (st: number, en: number) => {
  st = st * 1000;
  en = en * 1000;
  return moment(st).format('YYYY-MM-DD') === moment(en).format('YYYY-MM-DD')
    ? `${moment(st).format('LT')} - ${moment(en).format('LT')}`
    : `${moment(st).format('MMM Do')} ${moment(st).format('LT')} - ${moment(
        en,
      ).format('MMM Do')} ${moment(en).format('LT')}`;
};

interface IColumn {
  key: string;
  title: string;
  dataIndex?: string;
  render: any;
}

const oysterColumns = [
  {
    key: 'id',
    title: 'ID',
    render: (x: any) => <span>{x.id}</span>,
  },
  {
    key: 'season_name',
    title: 'Season',
    render: (x: IOysterHarvest) => <span>{x.season_name}</span>,
  },
  {
    key: 'complete_date',
    title: 'Harvested date',
    render: (x: IOysterHarvest) => (
      <span>{defaultDateFormat(x.complete_date)}</span>
    ),
  },
  {
    key: 'amount',
    title: 'Amount',
    render: (x: IOysterHarvest) => (
      <span>{`${formatNumber(x.amount_dz)} dozens`}</span>
    ),
  },
  {
    key: 'income',
    title: 'Income',
    render: (x: IOysterHarvest) => (
      <span>{`$${numberToMoneyStr(x.income ?? 0)}`}</span>
    ),
  },
  {
    key: 'is_full',
    title: 'F/P',
    render: (x: IOysterHarvest) => (
      <span>{x.is_full ? 'Full' : 'Partial'}</span>
    ),
  },
  {
    key: 'is_final',
    title: 'Final',
    render: (x: IOysterHarvest) => (
      <span>{x.is_final ? 'Final' : 'Middle'}</span>
    ),
  },
];

const seaweedColumns = [
  {
    key: 'id',
    title: 'ID',
    render: (x: ISeaweedHarvest) => <span>{x.id}</span>,
  },
  {
    key: 'season_name',
    title: 'Season',
    render: (x: ISeaweedHarvest) => <span>{x.season_name}</span>,
  },
  {
    key: 'complete_date',
    title: 'Harvested date',
    render: (x: ISeaweedHarvest) => (
      <span>{defaultDateFormat(x.complete_date)}</span>
    ),
  },
  {
    key: 'tank_area',
    title: 'Tank area',
    render: (x: ISeaweedHarvest) => <span>{`${x.tank_area} m²`}</span>,
  },
  {
    key: 'amount',
    title: 'Amount',
    render: (x: ISeaweedHarvest) => (
      <span>{`${formatNumber(x.amount)} kg`}</span>
    ),
  },
  {
    key: 'income',
    title: 'Income',
    render: (x: ISeaweedHarvest) => (
      <span>{`$${numberToMoneyStr(x.income ?? 0)}`}</span>
    ),
  },
  {
    key: 'is_full',
    title: 'F/P',
    render: (x: ISeaweedHarvest) => (
      <span>{x.is_full ? 'Full' : 'Partial'}</span>
    ),
  },
  {
    key: 'is_final',
    title: 'Final',
    render: (x: ISeaweedHarvest) => (
      <span>{x.is_final ? 'Final' : 'Middle'}</span>
    ),
  },
];

const columns = [
  {
    key: 'id',
    title: 'ID',
    render: (x: IMusselHarvest) => <span>{x.id}</span>,
  },
  {
    key: 'season_name',
    title: 'Season',
    render: (x: IMusselHarvest) => <span>{x.season_name}</span>,
  },
  {
    key: 'complete_date',
    title: 'Harvested date',
    render: (x: IMusselHarvest) => (
      <span>{defaultDateFormat(x.complete_date)}</span>
    ),
  },
  {
    key: 'line_length',
    title: 'Harvested length',
    render: (x: IMusselHarvest) => (
      <span>
        {x.line_length !== null ? `${formatNumber(x.line_length)} m` : '-'}
      </span>
    ),
  },
  {
    key: 'company',
    title: 'Company',
    render: (x: IMusselHarvest) => <span>{x.company}</span>,
  },
  {
    key: 'harvest_number',
    title: 'Harvest number',
    render: (x: IMusselHarvest) => <span>{x.harvest_number}</span>,
  },
  {
    key: 'vessel',
    title: 'Vessel',
    render: (x: IMusselHarvest) => <span>{x.vessel}</span>,
  },
  {
    key: 'time_tracks',
    title: 'Time Track',
    render: (x: IMusselHarvest) =>
      x.time_tracks &&
      x.time_tracks.map((t: any, i: number) => (
        <div key={i}>{displayHarvestTime(t.start_time, t.finish_time)}</div>
      )),
  },
  {
    key: 'harvester_name',
    title: 'Harvester name',
    render: (x: IMusselHarvest) => <span>{x.harvester_name}</span>,
  },
  {
    key: 'amount',
    title: 'Amount',
    render: (x: IMusselHarvest) => (
      <span>
        {x.is_waste ? 'Stripped to waste' : `${formatNumber(x.amount)} kg`}
      </span>
    ),
  },
  {
    key: 'income',
    title: 'Income',
    render: (x: IMusselHarvest) => (
      <span>{x.is_waste ? '' : `$${numberToMoneyStr(x.income ?? 0)}`}</span>
    ),
  },
  {
    key: 'is_full',
    title: 'F/P',
    render: (x: IMusselHarvest) => (
      <span>{x.is_full ? 'Full' : 'Partial'}</span>
    ),
  },
  {
    key: 'is_final',
    title: 'Final',
    render: (x: IMusselHarvest) => (
      <span>{x.is_final ? 'Final' : 'Middle'}</span>
    ),
  },
];

const growerColumns = [
  {
    key: 'id',
    title: 'ID',
    render: (x: IMusselHarvest) => <span>{x.id}</span>,
  },
  {
    key: 'complete_date',
    dataIndex: 'complete_date',
    title: 'Harvested date',
    render: (x: number) => <span>{defaultDateFormat(x)}</span>,
  },
  {
    key: 'bags_quantity',
    dataIndex: 'bags_quantity',
    title: 'Bags',
    render: (x: number) => <span>{x}</span>,
  },
  {
    key: 'port_of_unload',
    dataIndex: 'port_of_unload',
    title: 'Port of unload',
    render: (x: number) => <span>{x}</span>,
  },
  {
    key: 'rope_bags_quantity',
    dataIndex: 'rope_bags_quantity',
    title: 'Rope Bags',
    render: (x: number) => <span>{x}</span>,
  },
  {
    key: 'company',
    dataIndex: 'company',
    title: 'Company',
    render: (x: string) => <span>{x}</span>,
  },
  {
    key: 'harvest_number',
    dataIndex: 'harvest_number',
    title: 'Harvest number',
    render: (x: string) => <span>{x}</span>,
  },
  {
    key: 'vessel',
    dataIndex: 'vessel',
    title: 'Vessel',
    render: (x: string) => <span>{x}</span>,
  },
  {
    key: 'amount',
    title: 'Amount',
    render: (x: IMusselHarvest) => (
      <span>
        {x.is_waste ? 'Stripped to waste' : `${formatNumber(x.amount)} kg`}
      </span>
    ),
  },
  {
    key: 'income',
    title: 'Income',
    render: (x: IMusselHarvest) => (
      <span>{x.is_waste ? '' : `$${numberToMoneyStr(x.income ?? 0)}`}</span>
    ),
  },
  {
    key: 'is_full',
    dataIndex: 'is_full',
    title: 'F/P',
    render: (x: boolean) => <span>{x ? 'Full' : 'Partial'}</span>,
  },
];

interface IGalleryImages {
  original: string;
  thumbnail: string;
}

interface Props {
  harvests: IHarvestResource[];
  simple?: boolean;
  onUpdateRow?: (d: any) => void;
  type: TBusinessType;
  farmID: number;
}

const HarvestsTable = ({
  harvests,
  simple,
  onUpdateRow,
  type: businessType,
  farmID,
}: Props) => {
  const history = useHistory();
  const lang = useSelector(selectLang);
  const isGrower = SelectIsGrower(farmID);
  const accSetting = SelectAccountSetting(farmID);
  const myRole = SelectProfileRole(farmID);

  const [images, setImages] = useState<IGalleryImages[]>();
  const [infoText, setInfoText] = useState<string>();
  const [openHarvest, setOpenHarvest] = useState<IHarvestResource>();
  const [editHarvest, setEditHarvest] = useState<IHarvestResource>();
  const [orgSpats, setOrgSpats] = useState<IHarvestSpat[]>();

  const openImages = (images: string[]) => {
    setImages(
      images.map(image => {
        return {
          original: image,
          thumbnail: image,
        };
      }),
    );
  };
  const openInfo = (comment: any) => {
    setInfoText(comment && comment.length > 0 ? comment : 'No comments yet');
  };

  const tableColumns = () => {
    let result: IColumn[] =
      businessType === 'MUSSEL'
        ? isGrower
          ? [...growerColumns]
          : [...columns]
        : businessType === 'OYSTER'
        ? [...oysterColumns]
        : [...seaweedColumns];
    if (isGrower) {
      if (!simple)
        result.push(
          {
            key: 'expense_items',
            title: translate(lang, 'Items'),
            render: (x: IHarvestResource) =>
              !!x.expense_items && x.expense_items.length > 0 ? (
                <div
                  className='btn__modal'
                  onClick={e => {
                    e.stopPropagation();
                    setOpenHarvest(x);
                  }}
                >
                  {translate(lang, 'View')}
                </div>
              ) : (
                <span></span>
              ),
          },
          {
            key: 'pdf_file',
            title: 'PDF File',
            render: (x: IMusselHarvest) =>
              !!x.pdf_files ? (
                x.pdf_files.map((f, i) => (
                  <a
                    key={i}
                    className='btn__modal'
                    href={f}
                    target='_blank'
                    onClick={e => e.stopPropagation()}
                  >
                    {`${translate(lang, 'View')} (${i + 1})`}
                  </a>
                ))
              ) : (
                <span></span>
              ),
          },
        );
    } else {
      if (simple) {
        result.push({
          key: 'direction',
          title: translate(lang, 'Direction'),
          render: (x: IHarvestResource) => (
            <div>{directionChar(x.direction)}</div>
          ),
        });
      }
    }
    if (!simple) {
      result.push(
        {
          key: 'comment',
          dataIndex: 'comment',
          title: 'Comment',
          render: (x: any) => (
            <div
              className='btn__modal'
              onKeyDown={() => undefined}
              onClick={e => {
                e.stopPropagation();
                openInfo(x);
              }}
            >
              {translate(lang, 'View')}
            </div>
          ),
        },
        {
          key: 'images',
          dataIndex: 'images',
          title: translate(lang, 'Photo'),
          render: (x: any) =>
            !!x && x.length > 0 ? (
              <div
                className='btn__modal'
                onClick={e => {
                  e.stopPropagation();
                  openImages(x);
                }}
              >
                {translate(lang, 'View')}
              </div>
            ) : (
              <span></span>
            ),
        },
      );
    }
    if (businessType === 'MUSSEL' && !!accSetting?.track_original_spat) {
      result.push({
        key: 'harvest_spats',
        title: 'View original spats',
        render: (x: IMusselHarvest) =>
          !!x.harvest_spats && x.harvest_spats.length > 0 ? (
            <div
              className='btn__modal'
              onClick={e => {
                e.stopPropagation();
                setOrgSpats(x.harvest_spats);
              }}
            >
              {translate(lang, 'View')}
            </div>
          ) : (
            <span></span>
          ),
      });
    }
    result.push({
      key: 'action',
      title: '',
      render: (x: IHarvestResource) =>
        !!onUpdateRow &&
        checkRolePermission({ allowedRoles: ['owner', 'admin'] }, myRole) ? (
          <div
            onClick={e => {
              e.stopPropagation();
              setEditHarvest(x);
            }}
          >
            <EditFilled />
          </div>
        ) : (
          <></>
        ),
    });
    if (!accSetting?.show_budget_menu) {
      result = result.filter(x => x.key !== 'income');
    }
    return result.map(x => ({ ...x, title: translate(lang, x.title) }));
  };

  return (
    <div className='ant-table-wrapper table mt-17'>
      <Title
        className='ml-7'
        size={6}
        color='black-3'
        align='default'
        fontWeight={700}
      >
        {translate(lang, 'Harvests')}
      </Title>
      <Table
        rowKey='id'
        className='table table--isFarm pl-0 pr-0 overflow-auto'
        pagination={false}
        columns={tableColumns()}
        dataSource={[...harvests].sort(
          (a, b) => a.complete_date - b.complete_date,
        )}
        onRow={dataRow => ({
          onClick: () => history.push(`/harvest/${dataRow.id}`),
        })}
      />
      {infoText !== undefined && (
        <ModalComponent
          visible={true}
          onCancel={() => setInfoText(undefined)}
          type=''
          title='Comment'
          text={translate(lang, infoText)}
        />
      )}
      {!!images && images.length > 0 && (
        <Modal
          title='Images'
          centered
          visible={true}
          onOk={() => setImages(undefined)}
          onCancel={() => setImages(undefined)}
          width={1000}
        >
          <ReactImageGallery items={images} />
        </Modal>
      )}
      {!!openHarvest && (
        <GrowerDetailModal
          visible={true}
          items={openHarvest.expense_items ?? []}
          onClose={() => setOpenHarvest(undefined)}
        />
      )}
      {!!editHarvest && !!onUpdateRow && (
        <>
          {isGrower ? (
            <GrowerHarvestModal
              type={businessType}
              visible={true}
              lineData={editHarvest.line as any}
              onConfirm={d => {
                setEditHarvest(undefined);
                onUpdateRow(d);
              }}
              onClose={() => setEditHarvest(undefined)}
              harvestData={editHarvest as IMusselHarvest}
              updateId={editHarvest.id}
            />
          ) : (
            <HarvestCompleteModal
              type={businessType}
              visible={true}
              title='Update harvest data'
              lineData={editHarvest.line as any}
              data={editHarvest}
              onCancel={() => setEditHarvest(undefined)}
              onConfirm={d => {
                setEditHarvest(undefined);
                onUpdateRow(d);
              }}
              updateID={editHarvest.id}
            />
          )}
        </>
      )}
      {!!orgSpats && (
        <HarvestSpatsModal
          visible={true}
          onClose={() => setOrgSpats(undefined)}
          data={orgSpats}
        />
      )}
    </div>
  );
};

export default HarvestsTable;
