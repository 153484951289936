import moment from 'moment';
import { sendSingleRequest } from '../apis';
import {
  ILineResource,
  IOysterLineResource,
  ISpatStorageResource,
} from '../entities/farms.entities';
import { IAccountSetting } from '../entities/user.entities';
import { ISizedOyster } from '../entities/utils.entities';
import { defaultDateFormat } from '../util/toggleSecondMillisecond';

export const updateLineColumn = async (line_id: number, col_no: number) => {
  const response = await sendSingleRequest(
    { line_id, col_no },
    'POST',
    `api/farm/line/visual-line/column`,
    true,
  );
  if (response.status) {
    return response.data;
  } else {
    throw new Error(response.data?.message ?? 'Something went wrong');
  }
};

export const updateLinePriority = async (line_id: number, priority: number) => {
  const response = await sendSingleRequest(
    { line_id, priority },
    'POST',
    `api/farm/line/visual-line/priority`,
    true,
  );
  if (response.status) {
    return response.data;
  } else {
    throw new Error(response.data?.message ?? 'Something went wrong');
  }
};

type LineStatus =
  | 'EMPTY'
  | 'READY_HARVEST'
  | 'REQUIRE_ASSESSMENT'
  | 'CATCH_SPAT'
  | 'NORMAL';

export const getLineStatus = (
  line: ILineResource,
  expire_days = 90,
): LineStatus => {
  const ready_harvest = line.growing_cycle?.ready_harvest;
  const requireAssessment = () => {
    if (!line || !line.growing_cycle) return false;
    const lastDate =
      line.growing_cycle.last_assessment?.assessment_date ??
      line.growing_cycle.main_seed.planned_date_seed;
    const d1 = moment(Number(lastDate) * 1000)
      .toDate()
      .getTime();
    const d2 = moment().toDate().getTime();
    const d = Math.abs(Math.round((d2 - d1) / 86400000));

    return d > (expire_days ?? 90);
  };
  const catchSpat = !!line.growing_cycle?.main_seed.is_catch_spat;

  if (!line.growing_cycle) return 'EMPTY';
  else if (ready_harvest) return 'READY_HARVEST';
  else if (requireAssessment()) return 'REQUIRE_ASSESSMENT';
  else if (catchSpat) return 'CATCH_SPAT';
  else return 'NORMAL';
};

export const setFarmVisualFloatsPosition = async (
  seeding_id: number,
  inventory_id: number,
  positions: number[],
  shouldQueue: boolean = false,
) => {
  setLocalFloatsPosition(seeding_id, inventory_id, positions);

  if (shouldQueue) {
    if (!!(window as any).VisualFloatsPositionTimer) {
      clearTimeout((window as any).VisualFloatsPositionTimer);
    }
    if (!(window as any).VisualFloatsPositionQueue) {
      (window as any).VisualFloatsPositionQueue = [];
    }
    (window as any).VisualFloatsPositionQueue.push({
      seeding_id,
      inventory_id,
      positions,
    });
    (window as any).VisualFloatsPositionTimer = setTimeout(async () => {
      if (
        !!(window as any).VisualFloatsPositionQueue &&
        (window as any).VisualFloatsPositionQueue.length > 0
      ) {
        await sendSingleRequest(
          { bulk_data: (window as any).VisualFloatsPositionQueue },
          'POST',
          `api/farm/line/visual-floats-position`,
          true,
        );
        (window as any).VisualFloatsPositionQueue = null;
      }
    }, 3000);
  } else {
    const response = await sendSingleRequest(
      { seeding_id, inventory_id, positions },
      'POST',
      `api/farm/line/visual-floats-position`,
      true,
    );
    return response.status;
  }
};

export const getLocalFloatsPosition = (
  seeding_id: number,
  inventory_id: number,
) => {
  const res = localStorage.getItem(
    `farm_visual_floats_position_${seeding_id}_${inventory_id}`,
  );
  if (res) return JSON.parse(res);
  return null;
};

export const setLocalFloatsPosition = (
  seeding_id: number,
  inventory_id: number,
  positions: number[],
) => {
  localStorage.setItem(
    `farm_visual_floats_position_${seeding_id}_${inventory_id}`,
    JSON.stringify(positions),
  );
};

export const amountDays = (time: number | undefined | null): string => {
  if (time === null) {
    return '0 days';
  }

  if (Number(time) === 1) {
    return '1 day';
  }

  return `${time} days`;
};

export const calcOnGrowWastePercent = (
  onGrowWaste: IAccountSetting['oyster_crops'],
  type: 'grow' | 'waste',
  lineID: number | undefined = undefined,
) => {
  if (!lineID) {
    return (
      (type === 'grow' ? onGrowWaste?.grow_ons : onGrowWaste?.waste) ??
      undefined
    );
  } else {
    return (
      (type === 'grow'
        ? onGrowWaste?.lines_grow_ons &&
          onGrowWaste.lines_grow_ons[lineID] !== undefined &&
          onGrowWaste.lines_grow_ons[lineID] !== null
          ? onGrowWaste.lines_grow_ons[lineID]
          : onGrowWaste?.grow_ons
        : onGrowWaste?.lines_waste &&
          onGrowWaste.lines_waste[lineID] !== undefined &&
          onGrowWaste.lines_waste[lineID] !== null
        ? onGrowWaste.lines_waste[lineID]
        : onGrowWaste?.waste) ?? undefined
    );
  }
};

export const calcOysterPercent = (
  util: ISizedOyster,
  lineID: number | undefined,
) => {
  if (!lineID) {
    return util.num_val ?? undefined;
  } else {
    return util.json &&
      util.json.lines &&
      util.json.lines[lineID] !== undefined &&
      util.json.lines[lineID] !== null
      ? (util.json.lines[lineID] as number)
      : util.num_val ?? undefined;
  }
};

export const getEstHarvestDate = (line: ILineResource) =>
  line.growing_cycle?.last_assessment?.planned_date_harvest ??
  line.growing_cycle?.main_seed.planned_date_harvest;

export const calcLineLastSize = (line: ILineResource) => {
  if (!line.growing_cycle) return undefined;
  if (line.growing_cycle.last_assessment) {
    return line.growing_cycle.last_assessment.shell_size;
  }
  const min = line.growing_cycle.main_seed.spat_size;
  const max = line.growing_cycle.main_seed.spat_size_max ?? min;
  const avg = (min + max) / 2;
  return { min, max, avg };
};

export const getLineOysters = (
  line: IOysterLineResource,
  unit: 'dozens' | 'pieces' = 'dozens',
) => {
  if (!line.growing_cycle) return undefined;
  if (
    line.growing_cycle.last_assessment &&
    line.growing_cycle.last_assessment.estimated_amount
  ) {
    return unit === 'dozens'
      ? Math.round(line.growing_cycle.last_assessment.estimated_amount / 12)
      : line.growing_cycle.last_assessment.estimated_amount;
  }
  return unit === 'dozens'
    ? line.growing_cycle.current_amount_dz
    : line.growing_cycle.current_amount_pcs;
};

export const calcSpecieLineDozens = (
  line: IOysterLineResource,
  onGrowWaste: IAccountSetting['oyster_crops'],
  oysterSpecies: ISizedOyster[],
) => {
  if (!line.growing_cycle) return undefined;
  const totAmount = getLineOysters(line) ?? 0;
  const growPercent = calcOnGrowWastePercent(onGrowWaste, 'grow', line.id);
  const wastePercent = calcOnGrowWastePercent(onGrowWaste, 'waste', line.id);
  let result = [
    {
      name: 'Grown ons',
      amount:
        growPercent === undefined ? undefined : (totAmount * growPercent) / 100,
    },
    {
      name: 'Waste',
      amount:
        wastePercent === undefined
          ? undefined
          : (totAmount * wastePercent) / 100,
    },
  ];
  for (const field of oysterSpecies) {
    const percent = calcOysterPercent(field, line.id);
    result.push({
      name: field.name,
      amount: percent === undefined ? undefined : (totAmount * percent) / 100,
    });
  }
  return result;
};

export const calcOysterLineSize = (
  line: IOysterLineResource,
  onGrowWaste: IAccountSetting['oyster_crops'],
  oysterSpecies: ISizedOyster[],
) => {
  if (!line.growing_cycle) return undefined;
  if (line.growing_cycle.last_assessment) {
    return line.growing_cycle.last_assessment.shell_size;
  }
  let minArr: Array<{ size: number; amount: number }> = [];
  if (onGrowWaste?.grow_ons_json?.size_min) {
    const amount = calcOnGrowWastePercent(onGrowWaste, 'grow', line.id);
    if (amount !== undefined) {
      minArr.push({
        size: onGrowWaste.grow_ons_json.size_min,
        amount: amount,
      });
    }
  }
  if (onGrowWaste?.waste_json?.size_min) {
    const amount = calcOnGrowWastePercent(onGrowWaste, 'waste', line.id);
    if (amount !== undefined) {
      minArr.push({
        size: onGrowWaste.waste_json.size_min,
        amount: amount,
      });
    }
  }
  for (const field of oysterSpecies) {
    if (field.json.size_min) {
      const amount = calcOysterPercent(field, line.id);
      if (amount !== undefined) {
        minArr.push({
          size: field.json.size_min,
          amount: amount,
        });
      }
    }
  }
  let maxArr: Array<{ size: number; amount: number }> = [];
  if (onGrowWaste?.grow_ons_json?.size_max) {
    const amount = calcOnGrowWastePercent(onGrowWaste, 'grow', line.id);
    if (amount !== undefined) {
      maxArr.push({
        size: onGrowWaste.grow_ons_json.size_max,
        amount: amount,
      });
    }
  }
  if (onGrowWaste?.waste_json?.size_max) {
    const amount = calcOnGrowWastePercent(onGrowWaste, 'waste', line.id);
    if (amount !== undefined) {
      maxArr.push({
        size: onGrowWaste.waste_json.size_max,
        amount: amount,
      });
    }
  }
  for (const field of oysterSpecies) {
    if (field.json.size_max) {
      const amount = calcOysterPercent(field, line.id);
      if (amount !== undefined) {
        maxArr.push({
          size: field.json.size_max,
          amount: amount,
        });
      }
    }
  }
  const minSum = minArr.reduce((acc, x) => acc + x.amount, 0);
  if (minSum <= 0) {
    return undefined;
  }
  const min = minArr.reduce((acc, x) => acc + (x.size * x.amount) / minSum, 0);
  const maxSum = maxArr.reduce((acc, x) => acc + x.amount, 0);
  if (maxSum <= 0) {
    return { min, avg: min, max: min };
  }
  const max = maxArr.reduce((acc, x) => acc + (x.size * x.amount) / maxSum, 0);

  return { min, avg: (min + max) / 2, max };
};

const makeSpatLabel = (spat: ISpatStorageResource) => {
  let res: string[] = [];
  if (spat.source_line) {
    res.push(`${spat.source_line.farm.name}/${spat.source_line.line_name}`);
  } else if (spat.src_line_name) {
    res.push(spat.src_line_name);
  }
  if (spat.seed_type) res.push(spat.seed_type);
  res.push(defaultDateFormat(spat.collection_date) ?? '-');
  if (spat.source !== spat.src_line_name) res.push(spat.source);
  return res.join(', ');
};

export const getSpatSourceLabel = (
  spatID: number,
  spats: ISpatStorageResource[],
) => {
  const spat = spats.find(x => x.id === spatID);
  return spat ? makeSpatLabel(spat) : undefined;
};
